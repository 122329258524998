import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mooc-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit {
  @Input() value: string;
  @Input() icon: string;
  @Input() background: boolean = true;

  constructor() { }

  ngOnInit() { }
}
