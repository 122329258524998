import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';

@Component({
  selector: 'mooc-description-avatar',
  templateUrl: './description-avatar.component.html',
  styleUrls: ['./description-avatar.component.scss']
})
export class DescriptionAvatarComponent implements OnInit {

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<DescriptionAvatarComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: any, ) { }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
 
  ngOnInit() {    

  }
  


}
