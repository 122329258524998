
import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';



import * as firebase from 'firebase/app';

import { NotifyService } from '../notify-service/notify.service';

import { User } from './user.model';

import { MatDialog, MatDialogRef } from '@angular/material';

import { ModalIdentificationComponent } from '../../ui/modal-identification/modal-identification.component';
import { Subscription } from 'rxjs';
import { RouterService } from '../router/router.service';
import { XuserService } from '@area/user';
import { PublicX } from '@area/user/lib/core/xuser.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';

@Injectable()
export class AuthService {

  user: Observable<PublicX>;
  editPopupRef: MatDialogRef<ModalIdentificationComponent>;
  subs: Subscription;
  ip: string;

  // Agrega un nuevo campo para almacenar el uid personalizado
  customUid: string = 'zGiqJYM3JMMvZ8HXOGVdL6n0OeY2';

  constructor(
    private router: Router,
    private notify: NotifyService,
    public dialog: MatDialog,
    private rs: RouterService,
    private Xuser: XuserService,
    private http: HttpClient,
    private config: ConfigService
  ) {
    this.user = this.Xuser.xuser
    // console.log('this userrrrr: ', this.user)
    this.http.get('https://api.ipify.org/?format=json&callback=get_ip').pipe(
      map(data => this.ip = data["ip"]),
    ).subscribe()


  }

  isNew(): Observable<any> {
    return this.Xuser.isnew
  }

  CreateUserServer(userForm) {


    const roles = { student: true }
    const createdAt = new Date()
    const lastLogins = [{
      loginAt: new Date(),
      ipAddress: this.ip,
      institucion: this.config.checkSchool()['name']
    }]
    const institucion = [
      this.config.checkSchool()['name']
    ]
    const organizacion = [];
    const gender = '';

    const { displayName, email, identification, phone, photoURL, theme, typeIdentification, uid } = userForm

    // agregar info convenio usuario
    let dataConvenio = {
      uid:uid,
      nombres: displayName,
      documento: identification,
      correo: email,
      convenio: localStorage.getItem('convenio')
    }
    this.config.UpdateUserExtra(uid, dataConvenio);

    return this.Xuser.CreateUserServer({
      displayName, email, identification, phone, photoURL, theme, typeIdentification, uid, roles, createdAt, lastLogins, institucion, /* organizacion, gender */
    })
  }

  UpdateUserServer(id, dataUser) {
    //const { displayName, email, identification, phone, photoURL, theme, typeIdentification, uid } = dataUser;
    return this.Xuser.UpdateUserServer(id, dataUser)
  }

  UpdateUserLocal(id, dataUser) {
    //const { displayName, email, identification, phone, photoURL, theme, typeIdentification, uid } = dataUser;
    return this.Xuser.UpdateUserLocal (id, dataUser)
  }

  return() {
    let previous = this.rs.getPreviousUrl()
    if (previous != null) {
      this.router.navigate([previous]);
    } else {
      this.router.navigate(['/escritorio']);
    }
  }
  isAuthenticated() {
    return this.Xuser.xauth
  }
  GetUserServer(uid: string) {
    return this.Xuser.GetUserServer(uid)
  }
  // Cierre de sesion
  signOut() {
    this.Xuser.SingOut();
  }
  initUser() {
    this.Xuser.InitUserLoad()
  }


  //Restablecer la contraseña por medio de un correo electrónico
  resetPassword(email: string) {
    const fbAuth = firebase.auth();
    return fbAuth.sendPasswordResetEmail(email)
      .then(() => this.notify.openSnackBar('Correo electrónico de actualización de contraseña enviado', 'Continuar'))
      .catch((error) => this.handleError(error));
  }

  // Manejo de errores -> console log and notify user
  private handleError(error) {
    var errorCode = error.code;
    var errorMessage = '';

    switch (errorCode) {
      case 'auth/email-already-in-use': {
        errorMessage = 'Correo electrónico ya está en uso.';
        break;
      }
      case 'auth/invalid-email': {
        errorMessage = 'Correo electrónico no es válido.';
        break;
      }
      case 'auth/operation-not-allowed': {
        errorMessage = 'Acción no autorizada.'
        break;
      }
      case 'auth/weak-password': {
        errorMessage = 'Contraseña no es suficientemente fuerte.';
        break;
      }
      case 'auth/user-disabled': {
        errorMessage = 'Correo electrónico ha sido deshabilitado.';
        break;
      }
      case 'auth/user-not-found': {
        errorMessage = 'Ningún usuario corresponde al correo electrónico ingresado.';
        break;
      }
      case 'auth/wrong-password': {
        errorMessage = 'La contraseña no es válida para el correo electrónico ingresado, o la cuenta correspondiente al correo electrónico no tiene una contraseña establecida.';
        break;
      }
      case 'auth/network-request-failed': {
        errorMessage = 'Algo ha fallado, Revisa tu conexión a internet e intenta nuevamente';
        break;
      }
      default: {
        errorMessage = error.message;
        break;
      }
    }
    this.notify.openSnackBar(errorMessage, 'Aceptar');
  }

  ///// Role-based Authorization //////
  canRead(user: User): boolean {
    const allowed = ['admin', 'editor', 'student']
    return this.checkAuthorization(user, allowed)
  }

  canEdit(user: User): boolean {
    const allowed = ['admin', 'editor']
    return this.checkAuthorization(user, allowed)
  }

  canDelete(user: User): boolean {
    const allowed = ['admin']
    return this.checkAuthorization(user, allowed)
  }

  // determines if user has matching role
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) return false
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true
      }
    }
    return false
  }

}


