import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class RouterService {

	private previousUrl: string = undefined;
	private currentUrl: string = undefined;
	public id: number = undefined;

	constructor(private router: Router) {
		this.currentUrl = this.router.url;
		router.events.pipe(
			filter(e => e instanceof NavigationEnd),
		).subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.id = event.id
				this.previousUrl = this.currentUrl;
				this.currentUrl = event.url;
			};
		});
	}

	public getPreviousUrl() {
		return this.id > 1 ? this.previousUrl: null
	}
	public goRoute(path){
		this.router.navigateByUrl(path);
	}
}
