import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'mooc-modal-legal',
  templateUrl: './modal-legal.component.html',
  styleUrls: ['./modal-legal.component.scss']
})
export class ModalLegalComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ModalLegalComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: any,
  ) { }

  ngOnInit() {
  }

}
