import { Action } from '@ngrx/store';
import { UserScore, StateProgress } from "./user-score";
import { User } from '@firebase/auth-types';

export enum UserScoreActionTypes {
	// ADD_ONE_USER = '[User] Agregar un usuario',
	ADD_ONE = '[UserScore] Agregar un score',
	SAVE_ONE_SCORE  = '[UserScore] guardar un score',
	SELECT_ONE_SCORE  = '[UserScore] Selecciona un score',
	UPDATE_ONE_SCORE = '[UserScore] Actualizar un score',
	DELETE_ONE = '[UserScore] Borrar un score',
	REQUESTED_ALL_SCORES = '[UserScore] Solicitar todos los score',
	LOAD_ALL_SCORES = '[UserScore] score cargados',

	GET_PROGRESS = '[test] Solicitar los progresos',
	SAVE_PROGRESS = '[UserProgress] guardar un progreso',
	ADD_ONE_PROGRESS = '[UserProgress] Agregar un progreso',
	UPDATE_PROGRESS = '[UserProgress] Update un progreso',
	LOAD_ALL_PROGRESS = '[UserProgress] cargar todos los progresos',
	
}
//*
//* Esta accion guarda un nuevo score en la tienda, El efecto comprobara 
//* si el recurso existe, cuando no existe el progreso activa la accion AddOneScore,
//*
export class SaveOneScore implements Action {
	readonly type = UserScoreActionTypes.SAVE_ONE_SCORE;
	//constructor(public payload: UserScore) { }
	constructor(public payload: {id:string,change:any;}) { }
}
//*
//* Esta accion guarda los datos en firestore 
//*
export class AddOneScore implements Action {
	readonly type = UserScoreActionTypes.ADD_ONE;
	constructor(public score: UserScore) { }
}

export class SelectOneScore implements Action {
	readonly type = UserScoreActionTypes.SELECT_ONE_SCORE;
	constructor(public score: UserScore) { }
}
//*
//* Actualiza un score
//*


export class UpdateOneScore implements Action {
	readonly type = UserScoreActionTypes.UPDATE_ONE_SCORE;
	constructor(
		public id: string,
		public changes: Partial<UserScore>,
	) { }
}


//*
//* Solicita todos los score
//*
export class RequestedAllScores implements Action {
	readonly type = UserScoreActionTypes.REQUESTED_ALL_SCORES;
	constructor(public userUid: string) { }
}

//*
//* Agrega todos los scores despues de RequestedAllScores
//*
export class LoadAllScores implements Action {
	readonly type = UserScoreActionTypes.LOAD_ALL_SCORES;
	constructor(public scores: UserScore[]) { }
}








///****** progress
//*


export class GetProgressStates implements Action {
	readonly type = UserScoreActionTypes.GET_PROGRESS;
	constructor(public course: string) { }
}

//* Solicitar guardar un progreso
//*

export class saveProgress implements Action {
	readonly type = UserScoreActionTypes.SAVE_PROGRESS;
	constructor(public  payload: StateProgress){ }
}

//*
//* Agrega un progreso
//*

export class AddOneProgress implements Action {
	readonly type = UserScoreActionTypes.ADD_ONE_PROGRESS;
	constructor(public payload: StateProgress){ }
}


//*
//* Agrega todos los scores despues de RequestedAllScores
//*
export class LoadAllProgress implements Action {
	readonly type = UserScoreActionTypes.LOAD_ALL_PROGRESS;
	constructor(public  payload:{  progress:StateProgress[] }){ }
}

//*
//* Actualiza un progress
//*
export class UpdateOneProgress implements Action {
	readonly type = UserScoreActionTypes.UPDATE_PROGRESS;
	constructor(
		public id: string,
		public changes: Partial<StateProgress>,
	) { }
}




export type UserScoreActions
	= AddOneScore
	| GetProgressStates
	| UpdateOneScore
	| LoadAllScores
	| RequestedAllScores
	| SaveOneScore
	| saveProgress
	| LoadAllProgress
	| UpdateOneProgress
	| AddOneProgress
	

	// | AddOneUser
//   | DeleteOne
//   | GetAll;