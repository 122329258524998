import { Action } from '@ngrx/store';
import { User, Enrollment } from './user';
import { Update } from '@ngrx/entity';
import { Lesson } from '@app/courses/core/model/lesson';


export enum UserActionTypes {
	USER_LOAD = '[User] Cargar un usuario',
	GET_ENROLLMENTS = '[User Enroll] Buscar enrollments',
	ADD_ENROLLMENT = '[User Enroll] Agregar un enrollment',
	LOAD_ENROLLMENTS = '[User Enroll] Cargar todos los enrollments',
	UPDATE_ENROLLMENT = '[User Enroll] Actualizar enrollment',
	ADD_ONE_ENROLLMENT = '[User Enroll] Agregar un enrollment',
	CREATE_ENROLLMENT = '[User Enroll] Crear enrollment',
	

	GET_USER = '[Auth] Solicitar un usuario',
	DELETE_USER = '[Auth] Eliminar datos usuario',
	AUTHENTICATED = '[Auth] Usuario autenticado',
	NOT_AUTHENTICATED = '[Auth] Usuario no autenticado',
	// GOOGLE_LOGIN = '[Auth] Google login attempt',
	LOGOUT = '[Auth] Cerrar sesion',
	AUTH_ERROR = '[Auth] Error',
}

export class GetUser implements Action {
	readonly type = UserActionTypes.GET_USER;
    constructor(public payload?: any) {}
}

export class Authenticated implements Action {
	readonly type = UserActionTypes.AUTHENTICATED;
    constructor(public payload?: any) {}
}

export class NotAuthenticated implements Action {
	readonly type = UserActionTypes.NOT_AUTHENTICATED;
    constructor(public payload?: any) {}
}

export class AuthError implements Action {
	readonly type = UserActionTypes.AUTH_ERROR;
    constructor(public payload?: any) {}
}


// /// Google Login Actions

// export class GoogleLogin implements Action {
// 	readonly type = UserActionTypes.GOOGLE_LOGIN;
// 	constructor(public payload?: any) {}
//  }
 
 /// Logout Actions
 
 export class Logout implements Action {
	readonly type = UserActionTypes.LOGOUT;
	constructor(public payload?: any) {}
 }



/// cargar un usuario
export class UserLoad implements Action {
	readonly type = UserActionTypes.USER_LOAD;
	constructor(public payload: { user: User }) { }
}
export class deleteUser implements Action {
	readonly type = UserActionTypes.DELETE_USER;
}

// enrollment
export class GetEnrollments implements Action {
	readonly type = UserActionTypes.GET_ENROLLMENTS;
	constructor(public  idUser: string) { }
}

export class loadEnrollments implements Action {
	readonly type = UserActionTypes.LOAD_ENROLLMENTS;
	constructor(public payload: { enrollment: Enrollment[] }) { }
}

export class AddOneEnrollment implements Action {
	readonly type = UserActionTypes.ADD_ONE_ENROLLMENT;
	constructor(public payload:{ courseName: string, coursePath: string, courseId:string, firstlesson?:any}) { }
}

export class CreateEnrollment implements Action {
	readonly type = UserActionTypes.CREATE_ENROLLMENT;
	constructor(public payload: { enrollment: Enrollment }) { }
}

export class UpdateEnrollment implements Action {
	readonly type = UserActionTypes.UPDATE_ENROLLMENT;
	constructor(
		public id: string,
		public changes: Partial<Enrollment>,
	) { }	
}





export type UserActions = 
| UserLoad 
| GetEnrollments
| loadEnrollments
| UpdateEnrollment
| AddOneEnrollment
| CreateEnrollment
| GetUser 
| deleteUser
| Authenticated
| NotAuthenticated
// | GoogleLogin
| AuthError
| Logout;




