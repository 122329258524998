import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from '@app/shared/shared.module';
import { StepperSliderComponent } from '../stepper-slider/stepper-slider.component';


import { ModalIdentificationComponent } from '../modal-identification/modal-identification.component';
import { CdkStepperModule } from '@angular/cdk/stepper'; // this is the relevant important
import { NavigatorComponent } from '../stepper-slider/navigator/navigator.component';
import { MoocStepComponent } from '../stepper-slider/stepper-slider.component';
import { StepOneComponent } from '../modal-identification/step-one/step-one.component';
import { StepTwoComponent } from '../modal-identification/step-two/step-two.component';
import { StepThreeComponent } from '../modal-identification/step-three/step-three.component';
import { StepFourComponent } from '../modal-identification/step-four/step-four.component';
import { DescriptionAvatarComponent } from '../modal-identification/step-three/description-avatar/description-avatar.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    CdkStepperModule,
    
    

  ],
  declarations: [
    StepperSliderComponent,
    NavigatorComponent,
    ModalIdentificationComponent,
    MoocStepComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    StepFourComponent,
    DescriptionAvatarComponent

  ],
  exports: [
    StepperSliderComponent,
    NavigatorComponent,
    MoocStepComponent
  ],
  entryComponents: [ModalIdentificationComponent,DescriptionAvatarComponent],
})
export class UiModule { }
