export interface IUser {
	uid: string;
	displayName: string;
	loading?: boolean;
	error?: string;
}

export class User implements IUser {
	constructor(
		public uid: string,
		public displayName: string,
		public email?: string,
		public emailVerified?: boolean
	) { }
}

export interface Enrollment {
	courseName: string,
	coursePath: string,
	courseId:string,
	createdAt?: Date;
	lastUpdate?: Date;
	progress?: number;
	lastLection?: {
		title: string;
		path: string;
		order: number;
	};
	idUser:string;
	id?:string;
	institucion:string;

}

//Data User
// export interface User {
// 	uid?: string;
// 	email?: string;
// 	photoURL?: string;
// 	displayName?: string;
// 	roles?: Roles;
// 	identification?: string;
// 	typeIdentification?: string;
// 	createdAt?: Date;
// 	lastLogins?: lastLogins;
//    }

//Define Rol
export interface Roles {
	student?: boolean;
	editor?: boolean;
	admin?: boolean;
}

//Define Rol
export interface lastLogins {
	[index: number]: {
		loginAt?: Date;
		ipAddress?: string;
	};

}