import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RouterService } from '@app/core/router/router.service';
import { AuthService } from '../../core/auth/auth.service';

@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent {

  constructor(
	  public auth: AuthService,
	  private routerService: RouterService,) {

	   }

  logout() {
    this.auth.signOut();
  }
  goUrl(path) {
		this.routerService.goRoute(path)
	}
}
