

import {
	ActionReducerMap,
	MetaReducer,
	createFeatureSelector
} from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';


import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
//   import { debug } from './meta-reducers/debug.reducer';
//   import { AuthState } from './auth/auth.models';
//   import { authReducer } from './auth/auth.reducer';
import { RouterStateUrl } from './router/router.state';
import { environment } from '@env/environment';
import * as fromInterfaz from './interfaz/interfaz.reducer';

export const reducers: ActionReducerMap<AppState> = {
	// auth: authReducer,
	router: routerReducer,

	interfaz: fromInterfaz.reducer,
};

export const metaReducers: MetaReducer<AppState>[] = [
	initStateFromLocalStorage
];


//   export const selectAuthState = createFeatureSelector<AppState, AuthState>(
//     'auth'
//   );

export const selectRouterState = createFeatureSelector<
	AppState,
	RouterReducerState<RouterStateUrl>
>('router');

export interface AppState {
	// auth: AuthState;
	router: RouterReducerState<RouterStateUrl>;
	interfaz: fromInterfaz.State;
}