import { Component, ViewChild, OnDestroy, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from '../../../core/services/config.service';
import { take, takeUntil, tap, map } from 'rxjs/operators';
import { trigger, transition, query, style, stagger, animate, keyframes } from '@angular/animations';
import { MatGridList, MatBottomSheetRef, MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { DescriptionAvatarComponent } from './description-avatar/description-avatar.component';


export interface avatar {
	name: string,
	description: string,
	photo: string
}


@Component({
	selector: 'step-three-component',
	templateUrl: './step-three.component.html',
	styleUrls: ['./step-three.component.scss'],
})
export class StepThreeComponent implements OnDestroy {
	StepThree: FormGroup;
	types: Observable<avatar[]>;
	@ViewChild("grid", { static: false }) grid: MatGridList;
	@Input() photoURL: string = "";
	breakpoint: number;

	gridByBreakpoint = {
		xl: 8,
		lg: 5,
		md: 4,
		sm: 3,
		xs: 2
	};
	constructor(
		private formBuilder: FormBuilder,
		private configService: ConfigService,
		private observableMedia: MediaObserver,
		private _bottomSheet: MatBottomSheet
	) {
		this.StepThree = this.formBuilder.group({
			photoURL: ['', Validators.required],
		});
	}

	private unsubscribe$: Subject<void> = new Subject<void>();

	ngOnInit() {
		this.types = this.configService.getAvatars().pipe(
			map(data => {
				// Asignar los valores iniciales
				// si no es registrado por google
				if (this.photoURL === null) this.StepThree.setValue({
					photoURL: data[0].photo,
				})
				// Si posee datos
				else this.StepThree.setValue({
					photoURL: this.photoURL
				})
				// Si el usuario posee imagen la incluimos en la lista de selección
				return this.photoURL !== null ?
					[{
						name: 'default',
						photo: this.photoURL,
						description: null
					}, ...data]
					: data

			}),
			take(1),
		)



	}

	openBottomSheet(name: string, description: string): void {
		this._bottomSheet.open(DescriptionAvatarComponent, {
			panelClass: 'avatarpopup',
			data: {
				name, description
			}
		});
	}

	ngAfterContentInit() {
		this.observableMedia.media$.pipe(
			takeUntil(this.unsubscribe$)
		).subscribe((change: MediaChange) => {
			this.grid.cols = this.gridByBreakpoint[change.mqAlias];
		});
	}
	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

}

// @Component({
// 	selector: 'bottom-sheet-overview-example-sheet',
// 	template: '<div class="avatarpopup"><h4>{{data.name}}</h4><p>{{data.description}}</p></div>',
// })
// export class BottomSheetOverviewExampleSheet {
// 	constructor(
// 		private _bottomSheetRef: MatBottomSheetRef<BottomSheetOverviewExampleSheet>,
// 		@Inject(MAT_BOTTOM_SHEET_DATA)
// 		public data: any,
// 	) { }

// 	openLink(event: MouseEvent): void {
// 		this._bottomSheetRef.dismiss();
// 		event.preventDefault();
// 	}
// }