import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mooc-index-animation',
  templateUrl: './index-animation.component.html',
  styleUrls: ['./index-animation.component.scss']
})
export class IndexAnimationComponent implements OnInit {
  public lottieConfigComoFunciona: Object;

  constructor() { }

  ngOnInit() {
    this.lottieConfigComoFunciona = {
			path: './assets/animations/index/data.json',
			renderer: 'svg',
			autoplay: true,
			loop: true
		};
  }

}
