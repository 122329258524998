import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Interfaz } from './interfaz.model';
import { InterfazActions, InterfazActionTypes } from './interfaz.actions';

export interface State extends EntityState<Interfaz> {
	// additional entities state properties
}

export const adapter: EntityAdapter<Interfaz> = createEntityAdapter<Interfaz>();

export const initialState: State = adapter.getInitialState({
	ids: ['sidenavMenu'],
	entities: {
		'sidenavMenu': {
			state: false,
			id: 'sidenavMenu',
		},
	}
});

export function reducer(
	state = initialState,
	action: InterfazActions
): State {
	switch (action.type) {
		case InterfazActionTypes.AddInterfaz: {
			return adapter.addOne(action.payload.interfaz, state);
		}

		case InterfazActionTypes.UpsertInterfaz: {
			return adapter.upsertOne(action.payload.interfaz, state);
		}

		case InterfazActionTypes.AddInterfazs: {
			return adapter.addMany(action.payload.interfazs, state);
		}

		case InterfazActionTypes.UpsertInterfazs: {
			return adapter.upsertMany(action.payload.interfazs, state);
		}

		case InterfazActionTypes.UpdateInterfaz: {
			return adapter.updateOne(
				{
					id: action.id,
					changes: action.changes,
				}, state
			);
		}

		case InterfazActionTypes.UpdateInterfazs: {
			return adapter.updateMany(action.payload.interfazs, state);
		}

		case InterfazActionTypes.DeleteInterfaz: {
			return adapter.removeOne(action.payload.id, state);
		}

		case InterfazActionTypes.DeleteInterfazs: {
			return adapter.removeMany(action.payload.ids, state);
		}

		case InterfazActionTypes.LoadInterfazs: {
			return adapter.addAll(action.payload.interfazs, state);
		}

		case InterfazActionTypes.ClearInterfazs: {
			return adapter.removeAll(state);
		}

		default: {
			return state;
		}
	}
}

export const {
	selectIds,
	selectEntities,
	selectAll,
	selectTotal,
} = adapter.getSelectors();
