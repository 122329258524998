import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { environment } from '@env/environment';
// @ngrx
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';


// @firestore
import { FirebaseOptionsToken } from '@angular/fire';
export const firebaseConfig = environment.firebaseConfig;
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { localStorageProviders } from '@ngx-pwa/local-storage';

// @mooc
import { NotifyService } from './notify-service/notify.service';
import { AuthService } from './auth/auth.service';
import { CustomSerializer } from './router/custom-serialize';

import { AnimationsService } from './animations/animations.service';
import { UserEffects } from './user/user.effects';
import { UserReducer } from '@app/core/user/user.reducer';
import { UserService } from '@app/core/user/user.service';
import { EnrollmentReducer } from '@app/core/user/enrollment.reducer';
import { reducers, metaReducers } from './core.state';

import { LocalStorageService } from './local-storage/local-storage.service';


import { GoogleAnalyticsEffects } from './google-analytics/google-analytics.effects';
import { ConfigService } from './services/config.service';
import { AreaUserModule } from '@area/user';


@NgModule({
	//   declarations: [MigrationComponent],
	imports: [

		CommonModule,
		HttpClientModule,
		AngularFireAuthModule,
		AngularFirestoreModule,
		AngularFirestoreModule.enablePersistence(),
		StoreModule.forRoot(reducers, { metaReducers, runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true } }),
		StoreModule.forFeature('user', UserReducer),
		StoreModule.forFeature('enrollment', EnrollmentReducer),
		EffectsModule.forFeature([UserEffects]),
		!environment.production ? StoreDevtoolsModule.instrument({
			name: 'Mooc Areandina'
		}) : [],
		StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
		EffectsModule.forRoot([GoogleAnalyticsEffects]),
		AreaUserModule
	],
	providers: [
		NotifyService,
		AuthService,
		ConfigService,
		LocalStorageService, AnimationsService, UserService,
		{ provide: FirebaseOptionsToken, useValue: environment.firebaseConfig },
		{ provide: FirestoreSettingsToken, useValue: {} },
		{ provide: RouterStateSerializer, useClass: CustomSerializer },

		localStorageProviders({ prefix: 'MOOCS' })
	],
	exports: [
		AreaUserModule
	]
})
export class CoreModule {
	constructor(
		@Optional()
		@SkipSelf()
		parentModule: CoreModule
	) {
		if (parentModule) {
			throw new Error('CoreModule is already loaded. Import only in AppModule');
		}
	}
}
