import { createFeatureSelector, createSelector } from '@ngrx/store';


import * as fromUser from './user.reducer';
import { User, Enrollment } from '@app/core/user/user';
import { UserState } from '../user/user.reducer';
import * as fromEnrollment from './enrollment.reducer';

export const selectUserState = createFeatureSelector<UserState>("user");
export const selectEnrollmentsState = createFeatureSelector<fromEnrollment.EnrollmentState>("enrollment");


// export const selectUserIds = createSelector(
// 	selectUserState,
// 	fromUser.selectIds
// );
// export const selectUser = createSelector(
// 	selectUserIds,
// 	selectUserIds => coursesState.entities[1]
// );


export const selectUserIds = createSelector(
	selectUserState,
	fromUser.selectIds
);
export const selectUserById = (userUid:number| string) => createSelector(
	selectUserState,
	users => users.entities[userUid]
);
export const getIdCurrentUser = createSelector(
	selectUserIds,
	selectUserId => selectUserId[0]		
	
);
export const selectCurrentUser = createSelector(
	selectUserState,getIdCurrentUser,
	(users, currentUser) => {
		return users.entities[currentUser]
	}
);

export const selectEnrollments = createSelector(
	selectEnrollmentsState,
	fromEnrollment.selectAll
);
export const selectEnrollmentsByCourse = (idcourse:string) => createSelector(
	selectEnrollments,
	enrrollment => {const [data] =  enrrollment.filter(enroll => enroll.courseId === idcourse); return data} 
);

export const selectEnrollmentsIds = createSelector(
	selectEnrollmentsState,
	fromEnrollment.selectIds
);

export const EnrollmentLoaded = createSelector(
	selectEnrollmentsState,
	enrollmentState => enrollmentState.loaded
);

