import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MoocErrorMatcher, ValidateService } from '../validate.service';
import { Store } from '@ngrx/store';
import { ActionSettingsChangeTheme, ActionSettingsPersist, selectorSettings, SettingsState } from '../../../settings';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


export interface themes {
	value: string;
	viewValue: string;
}

@Component({
	selector: 'step-four-component',
	templateUrl: './step-four.component.html',
	styleUrls: ['./step-four.component.scss'],
})
export class StepFourComponent implements OnDestroy {
	StepFour: FormGroup;
	errorMatcher = new MoocErrorMatcher();
	unsubscribe$: Subject<void> = new Subject<void>();
	settings: SettingsState;

	// Lista de temas
	themes: themes[] = [
		{ viewValue: 'Claro', value: 'LIGHT-THEME' },
		{ viewValue: 'Oscuro', value: 'BLACK-THEME' },
	];


	constructor(
		private formBuilder: FormBuilder,
		private validate: ValidateService,
		private store: Store<any>
	) {

		store
			.select(selectorSettings)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(settings => {
				this.settings = settings
			}

			);
		this.StepFour = this.formBuilder.group({
			theme: [this.settings.theme ? this.settings.theme : 'LIGHT-THEME', Validators.required],
		});
	}

	getErrorMessage(control): string {
		return this.validate.ErrorMessage(control)
	}


	ngOnInit() {
		this.StepFour.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(
			data => {
				const theme = data.theme
				if(data.theme !== null) this.store.dispatch(new ActionSettingsChangeTheme({ theme })),
				this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
			}
		)
	}

	change(event) {
		
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}


}