import { Injectable } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class ValidateService {

	constructor() { }

	public ErrorMessage(control: FormControl): string {
		if (control) {
			if (control.hasError('required')) return 'Este campo es obligatorio';
			if (control.hasError('maxlength')) return 'Ha exedido el numero maximo de caracteres';
		}
		return '';
	}
}

/** Error de los formularios */
export class MoocErrorMatcher implements ErrorStateMatcher {
	constructor() { }
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

