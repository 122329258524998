

import { AuthService } from '../../core/auth/auth.service';


import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { RouterService } from '@app/core/router/router.service';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/core.state';
import { UpdateInterfaz } from '@app/core/interfaz/interfaz.actions';

@Component({
	selector: 'user-menu',
	templateUrl: './user-menu.component.html',
	styleUrls: ['./user-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent implements OnInit {


	@Output() eventClick: EventEmitter<any> = new EventEmitter();

	constructor(
		public auth: AuthService,
		private routerService: RouterService,
		private store: Store<AppState>,
	) {
   // console.log(auth)
	}

	logout() {
		this.auth.signOut();
	}
	closeSidenav() {
		setTimeout(() => {
			this.store.dispatch(new UpdateInterfaz("sidenavMenu", {
				state: false
			}));
		},500);

	}
	goUrl(path) {
		this.routerService.goRoute(path)
	}
	ngOnInit() {

	}



}
