import { Component, OnInit, AfterViewChecked, AfterViewInit, } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, take, tap } from 'rxjs/operators';
import { ConfigService } from '../../core/services/config.service';
import { Observable, ObjectUnsubscribedError } from 'rxjs';
import { ExcelService } from '@app/core/services/excel.service';

@Component({
  selector: 'mooc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit {
  config$: Observable<any>;
  social: any;
  contacts: any;
  dataReport: any = [];
  dataUsers: any = [];
  data: any = [];
  newArray: any[] = [];

  configInstitucion = []; 
	configData: any;
	datareport: any[] = [];
	Usuarios: any[] = [];
	Enroll: any[] = [];
  constructor(public service: ConfigService, private excelService: ExcelService) {
  }

  ngOnInit() {
//    this.getUsers();
   //this.delete();
  }
  ngAfterViewInit() {
    this.config$ = this.service.getConfig().pipe(
      take(1)
    )
  }
  async getUsers() {

		let response = await this.service.getUsers().subscribe(res => {
			console.log('users done!!');
			console.log(res.length);
			console.log(res);
			this.Usuarios = res;
			//this.excelService.exportAsExcelFile(res, 'Usarios');
			setTimeout(() => {
			  this.getEnrollments();
				//this.excelService.exportAsExcelFile(res, 'Usuarios');
			}, 1000);

		}, error => {
			console.log(error)
		});

	}

	async getEnrollments() {

		let response = await this.service.getEnrollments().subscribe(res => {
			console.log('enrolments done!!');
			console.log(res.length);
			console.log(res);
			this.Enroll = res;
			setTimeout(() => {
        //this.makeReport();
        //this.excelService.exportAsExcelFile(res, 'Enrollments');
			}, 1500);

		}, error => {
			console.log(error)
		});

	}

	async makeReport() {

		/* 		for (let i = 0; i < this.Enroll.length; i++) {

					this.searchInArray(this.Enroll[i])
					console.log(i)
					//console.log(this.Enroll[i]);
					if (i == this.Enroll.length - 1) {
						console.log(this.datareport);
						this.excelService.exportAsExcelFile(this.datareport, 'reporte');
					}
				} */

		for (let i = 0; i < this.Usuarios.length; i++) {
			console.log(this.Usuarios.length)
			console.log(i)
			this.searchInArray(this.Usuarios[i])
			if (i == this.Usuarios.length - 1) {
				console.log(this.datareport);
				setTimeout(() => {
					this.excelService.exportAsExcelFile(this.datareport, 'reporte_UsuariosEnrollment');
				}, 1000);
			}

		}


	}

	async searchInArray(usuario) {

		let flag = false;
		for (let i = 0; i < this.Enroll.length; i++) {

			//let fecha = this.getDateTimeFromTimestamp((this.Enroll[i]['createdAt']['seconds']) ? this.Enroll[i]['createdAt']['seconds'] : '');
			if (usuario['uid'] == this.Enroll[i]['idUser']) {
				this.datareport.push({
          ID_USUARIO: usuario['uid'],
          IDENTIFICACION: usuario['identification'],
          NOMBRE: usuario['displayName'],
          CORREO: usuario['email'],
          ID_CURSO: this.Enroll[i]['courseId'],
          NOMBRE_CURSO: this.Enroll[i]['courseName'],
          PROGRESO: this.Enroll[i]['progress'],
          CREATE_AT: this.Enroll[i]['createdAt']['seconds'] ? this.Enroll[i]['createdAt']['seconds'] : '',
          LASTLESSON: this.Enroll[i]['lastLection']['title'],
          INSTITUCION1: (this.Enroll[i]['institucion']) ? this.Enroll[i]['institucion'] : '',
          INSTITUCION2: (this.Enroll[i]['instancia']) ? this.Enroll[i]['instancia'][0] : '',
          INSTITUCION3: (this.Enroll[i]['instancia']) ? this.Enroll[i]['instancia'][1] : ''
				})
			}

		}



	}
	getDateTimeFromTimestamp(unixTimeStamp) {
		return new Date(unixTimeStamp * 1000).toLocaleDateString("es-CO").slice(0, 11).slice(0, 19).replace('T', ' ');
	}

  delete(){
    //console.log('deleting gender')
    this.service.deleteFields()
  }

}
