import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel/carousel.component';
import { CarruselcoursesComponent } from './carruselcourses/carruselcourses.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import { IndexAnimationComponent } from './index-animation/index-animation.component';
import { LottieAnimationViewModule } from 'ng-lottie';




// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [CarouselComponent, CarruselcoursesComponent, IndexAnimationComponent],
  imports: [
    CommonModule,
    IvyCarouselModule,
    MatCardModule,
    MatButtonModule,
    LottieAnimationViewModule.forRoot()
  ],
  exports: [
    CarouselComponent,
    CarruselcoursesComponent,
    IndexAnimationComponent,
    LottieAnimationViewModule

    // NgbModule
  ]
})
export class ComponentsModule { }
