import { Component, OnInit, Input } from '@angular/core';
import { ICarouselItem } from './Icarousel-item.metadata';
import { ConfigService } from '@app/core/services/config.service';
import { Observable } from 'rxjs';
import { RouterService } from '@app/core/router/router.service';

@Component({
  selector: 'mooc-carruselcourses',
  templateUrl: './carruselcourses.component.html',
  styleUrls: ['./carruselcourses.component.scss']
})
export class CarruselcoursesComponent implements OnInit {

  /**
   * Custom Properties
   */

   @Input() isFullScreen = false;
   @Input() items: ICarouselItem[] = [];
  
 
   widthScreen: number = window.innerWidth;
 
   // items: any[]=[];
 
   /**
    * Final Properties
*/
 
   constructor(public config: ConfigService,
    private routerService: RouterService) {
    
     
    }
 
    goUrl(path) {
      this.routerService.goRoute(path) 
    }
     
   ngOnInit() {
     //auto slide
 
     // traemos las imagenes del carousel desde base de datos
     this.config.getcourses().subscribe(res => {
       console.log('res: ', res)
 
       // con este for convertimos la respuesta en array para poderlo aasignar a this.items []
       var images = [];
       for(var i in res){
         images[i] = res[i];
       }
       this.items = images;
       console.log('this.items: ', this.items) 
     });
 
 
 
   } 
 
 }



 
 