import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { User, Enrollment } from './user';
import { UserActions, UserActionTypes } from './user.actions';




export interface EnrollmentState extends EntityState<Enrollment> {
	loaded: boolean;
}

// function sortByCourseAndSeqNo(l1: Lesson, l2: Lesson) {
// 	const compare = l1.order - l2.order;
// 	if (compare != 0) {
// 		return compare;
// 	}
// 	else {
// 		return l1.order;
// 	}
// }


export const Enrollmentadapter: EntityAdapter<Enrollment> =
	createEntityAdapter<Enrollment>({
		selectId: enrollment => enrollment.courseId,
		// sortComparer: sortByCourseAndSeqNo
	});


const initialEnrollmentState = Enrollmentadapter.getInitialState({
	loaded: false
});


export function EnrollmentReducer(state = initialEnrollmentState,
	action: UserActions): EnrollmentState {

	switch (action.type) {

		case UserActionTypes.CREATE_ENROLLMENT:
			return Enrollmentadapter.addOne(action.payload.enrollment, state);
	
		case UserActionTypes.LOAD_ENROLLMENTS:
			// return adapter.addMany(action.payload.lessons, { ...state, loading: false });
			return Enrollmentadapter.addMany(action.payload.enrollment,  { ...state, loaded: true });
			
		case UserActionTypes.UPDATE_ENROLLMENT:
			return Enrollmentadapter.updateOne({
				id: action.id,
				changes: action.changes,
			}, state);
			// return adapter.addMany(action.payload.lessons, { ...state, loading: false });
		// loadEnrollments
		// case UserActionTypes.LOAD_ENROLLMENTS:
		// 	// return adapter.addMany(action.payload.lessons, { ...state, loading: false });
		// 	return Useradapter.addOne(action.payload.enrollment, state);


		default:
			return state;

	}
}



export const {
	selectAll,
	selectEntities,
	selectIds,
	selectTotal

} = Enrollmentadapter.getSelectors();




// export type Action = userActions.All;

// const defaultUser = new User(null, 'GUEST');

/// Reducer function
// export function userReducer(state: User = defaultUser, action: Action) {
// 	switch (action.type) {

// 		case UserActionTypes.GET_USER:
// 			return { ...state, loading: true };

// 		case UserActionTypes.AUTHENTICATED:
// 			return { ...state, ...action.payload, loading: false };

// 		case UserActionTypes.NOT_AUTHENTICATED:
// 			return { ...state, ...defaultUser, loading: false };

// 		case UserActionTypes.GOOGLE_LOGIN:
// 			return { ...state, loading: true };

// 		case UserActionTypes.AUTH_ERROR:
// 			return { ...state, ...action.payload, loading: false };

// 		case UserActionTypes.LOGOUT:
// 			return { ...state, loading: true };

// 	}
// }


