import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotifyService } from '../../core/notify-service/notify.service';
import { Subject } from 'rxjs';
import {
	AngularFirestore,

} from '@angular/fire/firestore';
import { StepOneComponent } from './step-one/step-one.component';
import { StepTwoComponent } from './step-two/step-two.component';
import { StepThreeComponent } from './step-three/step-three.component';
import { StepFourComponent } from './step-four/step-four.component';



@Component({
	selector: 'mooc-modal-identification',
	templateUrl: './modal-identification.component.html',
	styleUrls: ['./modal-identification.component.scss'],
})

export class ModalIdentificationComponent implements OnInit {

	isLinear = false;

	@ViewChild(StepOneComponent, { static: true }) firstFormGroup: StepOneComponent;
	@ViewChild(StepTwoComponent, { static: true }) secondFormGroup: StepTwoComponent;
	@ViewChild(StepThreeComponent, { static: true }) thirdFormGroup: StepThreeComponent;
	@ViewChild(StepFourComponent, { static: true }) fourthFormGroup: StepFourComponent;

	constructor(

		private dialogRef: MatDialogRef<ModalIdentificationComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: any,
		private afs: AngularFirestore,
		private notify: NotifyService,
	) {

	}

	get StepOne() {
		return this.firstFormGroup ? this.firstFormGroup.StepOne : null;
	}
	get StepTwo() {
		return this.secondFormGroup ? this.secondFormGroup.StepTwo : null;
	}
	get StepThree() {
		return this.thirdFormGroup ? this.thirdFormGroup.StepThree : null;
	}
	get StepFour() {
		return this.fourthFormGroup ? this.fourthFormGroup.StepFour : null;
	}


	private unsubscribe$: Subject<void> = new Subject<void>();

	ngOnInit() {
	}


	submit() {
		const finalUSer = Object.assign(
			{},
			this.data,
			{
				...this.firstFormGroup.StepOne.value,
				...this.secondFormGroup.StepTwo.value,
				...this.thirdFormGroup.StepThree.value,
				...this.fourthFormGroup.StepFour.value
			}
		)
		this.dialogRef.close(finalUSer);

		localStorage.setItem('convenio', this.secondFormGroup.StepTwo.value.convenio);
	}






	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

}
