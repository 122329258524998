import { Component, OnInit, Input, } from '@angular/core';
import { ICarouselItem } from './Icarousel-item.metadata';
import { ConfigService } from '@app/core/services/config.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  /**
   * Custom Properties
   */
  @Input() height = 240;
  @Input() isFullScreen = false;
  @Input() items: ICarouselItem[] = [];
  @Input() autoSlide = true;
  @Input() slideInterval = 8000;

  widthScreen: number = window.innerWidth;

  // items: any[]=[];

  /**
   * Final Properties
   */
  public finalHeight: string | number = 0;
  public currentPosition = 0;

  constructor(public config: ConfigService) {
    // this.finalHeight = this.isFullScreen ? '100vh' : `${this.height}px`;

    this.finalHeight = `${window.innerWidth * 0.185}px`;
    
   }

 
  

  ngOnInit() { 

   
    //auto slide

    // traemos las imagenes del carousel desde base de datos
    this.config.getImagesCarousel().subscribe(res => {
      console.log('res: ', res)

      // con este for convertimos la respuesta en array para poderlo aasignar a this.items []
      var images = [];
      for(var i in res){
        images[i] = res[i];
      }
     //this.items = images;      
     this.items=images.sort((x, y) => x.id - y.id);
     
     console.log('this.items: ', this.items)


      // aqui se inicia el movimiento delc arousel y posteriormente se hace lectura de las imagenes
      if(this.autoSlide) {
        this.autoSlideImages();
      }
      this.items.map( ( i, index ) => {
        i.id = index;
        i.marginLeft = 0;
      });


    });

    // this.items = [

    //   {
    //       id: 1,
    //       title: {
    //           first: '',
    //           second: ''
    //       },
    //       subtitle: '',
    //       link: '/',
    //       image: '../../../assets/images/carousel/Disrupcion-2019.jpg'
    //   },
    //   {
    //       id: 2,
    //       title: {
    //           first: '',
    //           second: ''
    //       },
    //       subtitle: '',
    //       link: '/',
    //       image: '../../../assets/images/carousel/Felicidad-2021.jpg'
    //   },
    //   {
    //       id: 3,
    //       title: {
    //           first: '',
    //           second: ''
    //       },
    //       subtitle: '',
    //       link: '/',
    //       image: '../../../assets/images/carousel/SAP-2019.jpg'
    //   },
  
    // ];

    // console.log('items: ', this.items)

    // if(this.autoSlide) {
    //   this.autoSlideImages();
    // }
    // this.items.map( ( i, index ) => {
    //   i.id = index;
    //   i.marginLeft = 0;
    // });

  }

  autoSlideImages(): void{
    setInterval(() =>{
      this.setNext()
    }, this.slideInterval);
  }

  setCurrentPosition(position: number) {
    this.currentPosition = position;
    this.items.find(i => i.id === 0).marginLeft = -100 * position;
  }

  abrirLink(link){
    window.open(link, '_blank');
  }

  sumaclick(tag,click){

    var aumentoclick = click+1;

    console.log(tag);
    console.log(aumentoclick);
    
    this.config.Updatenumbreclick(tag, aumentoclick);

    setTimeout(() => {
      
      location.reload(); 
    }, 500);

  }

  setNext() {
    let finalPercentage = 0;
    let nextPosition = this.currentPosition + 1;
    if (nextPosition <= this.items.length - 1) {
      finalPercentage = -100 * nextPosition;
    } else {
      nextPosition = 0;
    }
    this.items.find(i => i.id === 0).marginLeft = finalPercentage;
    this.currentPosition = nextPosition;
  }

  setBack() {
    let finalPercentage = 0;
    let backPosition = this.currentPosition  - 1;
    if (backPosition >= 0) {
      finalPercentage = -100 * backPosition;
    } else {
      backPosition = this.items.length - 1;
      finalPercentage = -100 * backPosition;
    }
    this.items.find(i => i.id === 0).marginLeft = finalPercentage;
    this.currentPosition = backPosition;

  }

}
