import { Component, OnInit, HostListener, Input } from '@angular/core';

import { Router } from '@angular/router';
import { RouterService } from '@app/core/router/router.service';

@Component({
	selector: 'mooc-btn-back',
	templateUrl: './btn-back.component.html',
	styleUrls: ['./btn-back.component.scss']
})
export class BtnBackComponent implements OnInit {

	constructor(
		private rs: RouterService,
		private router: Router,
	) { }

	ngOnInit() {
	}
	@Input() redirectFirst:string;
	@Input() label:string;
	@Input() tooltip:string;
	@Input() lastRoute:boolean = true;	

	@HostListener('click')
	onClick() {
	
		let previous = this.rs.getPreviousUrl()
		let defaultValue = '/' + this.redirectFirst;

		if(this.lastRoute){
			this.router.navigate([previous ? previous : defaultValue]);
		}else{
			this.router.navigate([defaultValue]);
		}
	}

}
