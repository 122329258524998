import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, } from '@angular/fire/firestore';
import { take, finalize, tap, map, switchMap, mapTo } from 'rxjs/operators';
import { Course, enrollment } from "../../../app/courses/core/model/course";

import { Store } from '@ngrx/store';
import { ActionSettingsChangeTheme } from '../../settings';
import { Observable, combineLatest, of } from 'rxjs'
import { uniq, flatten } from 'lodash'

import * as firebase from 'firebase/app';
import { xUserBase } from '@area/user/lib/core/xuser.model';

import { AdminService } from '@app/core/services/admin.service';



export interface avatar {
  name: string,
  description: string,
  photo: string
}
@Injectable()
export class ConfigService {
  urlInstitucion: any;
  nameInstitucion: any;
  EnrollCollection: AngularFirestoreCollection<enrollment>;
  joined$: Observable<any>



  constructor(public admin: AdminService, public afs: AngularFirestore, private store: Store<any>) { }


  getConfig() {
    const data = this.afs.collection('config').doc('contacts').valueChanges();
    return data
  }

  public getAvatars() {
    const data = this.afs.collection('config').doc<avatar[]>('avatars').valueChanges().pipe(
      map(data => data["payload"])
    );
    return data
  }

	/**
	 * ChangeTheme
	 */
  public changeTheme(theme) {
    // this.store.dispatch(new ActionSettingsChangeTheme(theme));
  }

  public checkSchool() {
    let response = {};
    this.urlInstitucion = window.location.origin;
    //console.log(this.urlInstitucion);
    if (this.urlInstitucion == 'http://localhost:5500') {
      this.nameInstitucion = 'istmo';
    } else if (this.urlInstitucion == 'http://localhost:4200' || this.urlInstitucion.includes('moocs-4355c') ||
      this.urlInstitucion.includes('233515') || this.urlInstitucion.includes('andina') || this.urlInstitucion.includes(8089) ||
      this.urlInstitucion.includes('areandina')) {
      this.nameInstitucion = 'areandina';
    } else if (this.urlInstitucion == 'http://localhost:5000' ||
      this.urlInstitucion == 'https://mooc-sanmarcos-test.firebaseapp.com' ||
      this.urlInstitucion == 'https://mirrordev-usanmarcos.web.app' ||
      this.urlInstitucion == 'https://mirrordev-usanmarcos.firebaseapp.com' ||
      this.urlInstitucion == 'https://mirrordev.usanmarcos.ac.cr'
    ) {
      this.nameInstitucion = 'sanmarcos';
    }
    response = ({ name: this.nameInstitucion, url: this.urlInstitucion });
    return response;
  }
  public getSchoolInfo(name: string) {
    return this.afs.collection('institucion').doc('' + name + '').valueChanges().pipe(take(1));
  }
  public getUsers() {

    return this.afs.collection('users').valueChanges().pipe(take(1));

  }

  public getEnrollments() {

    return this.afs.collection('enrollment').valueChanges().pipe(take(1));

  }

  public getcourses() {

    return this.afs.collection("moocs" , ref => ref.where('state', '==', true)).valueChanges();

  }

  public getImagesCarousel() {

  //return this.afs.collection('imgCarousel', ref => ref.where('status', '==', true).orderBy('id')).valueChanges();
  return this.afs.collection('imgCarousel', ref => ref.where('status', '==', true)).valueChanges();
  
  }

  Updatenumbreclick(tag, aumentoclick) {
 
    return this.afs.collection('imgCarousel').doc(tag).update({click: aumentoclick})

  }
  
  updateProgresEnrollMasive() {

    var enrolls = [
      {idEnroll:'FjAnRqfubLnuWiYpFkZA', recursos:19},
      {idEnroll:'NTlwBiIoJxP1XUzb4JVF', recursos:19},
      {idEnroll:'dr352Bn2Y794VnhOIyWI', recursos:19},
      {idEnroll:'hTV5vOesL7BJjuSyTmh8', recursos:19},
      {idEnroll:'Bdq2w7PAbo6jg0F3b3d4', recursos:19},
      {idEnroll:'iLQXea8dMF57ffO5PcFN', recursos:19},
      {idEnroll:'Pub6glHa2PTi2Z07bW4D', recursos:19},
      {idEnroll:'2bIEIQWX3eMGzzi7lEVR', recursos:19},
      ];

      enrolls.forEach(el => {
        console.log('el: ', el)
        // this.afs.collection('enrollment').doc(el.idEnroll).update({progress: 100, progressRaw: el.recursos})

      });
 
    // return this.afs.collection('imgCarousel').doc(tag).update({click: aumentoclick}) 

  }

  getCertifyAll(){

    this.afs.collection("certify").valueChanges().pipe().subscribe(res => {
      console.log('cantidad certificados: ', res.length)
      var certifyId = {};
      for(let i = 0; i < res.length; i++){
        var id = String(res[i]['uid']) + String(res[i]['courseId']);
        certifyId[String(id)] = res[i];
        // console.log('cert: ', id)
        if(i == res.length - 1){
          this.validaCertificadosUsuariosProgreso100(certifyId);
        }
      }

    });

  }

  validaCertificadosUsuariosProgreso100(certifyId){
    console.log('valida enrollment al 100: ', certifyId)
    // this.afs.collection("enrollment", ref => ref.where("progress", "==", 100)).valueChanges().pipe(take(1)).subscribe(res => {
    this.afs.collection("enrollment", ref => ref.where("progress", "==", 100)).valueChanges().pipe(take(1)).subscribe(enroll => {
      console.log("cantidad de enrollments: ", enroll.length)  
      
      for(let i = 0; i < enroll.length; i++){
        
        var idCert = String(enroll[i]['idUser']) + String(enroll[i]['courseId']);
        if(certifyId[String(idCert)] != undefined){
          // console.log('existe: ', enroll[i]['idUser'], ' ', enroll[i]['courseName'], ' ', idCert)
        }else{
          console.log('no: ', enroll[i]['idUser'], ' ', enroll[i]['courseName'], ' ', idCert)

          this.admin.getUserByIdOne(enroll[i]['idUser']).subscribe(res_user => {
            
            if (res_user.length > 0 && res_user[0]['identification'] ) {
              console.log(res_user[0])
              this.admin.getCourseById(enroll[i]['courseId']).subscribe(dataCourse => {
                
                this.generateCertify(res_user, dataCourse, enroll[i]['courseId'])
                console.log('uid: ', enroll[i]['idUser'], ' ', enroll[i]['courseId'], ' ', enroll[i]['courseName'])
              })
            }else{
              console.log(enroll[i]['idUser'], ' ', res_user)
            }
      
          })
        }
        
      }

    })

  }

  generateCertify(userdata, dataCourse, idCourse) {
    console.log('generateCertificado')
    let load = false;
    let data = {
      courseId: idCourse,
      courseName: dataCourse[0]['title'],
      coursePath: dataCourse[0]['path'],
      displayName: userdata[0]['displayName'],
      email: userdata[0]['email'],
      identification: userdata[0]['identification'],
      timeIssue: new Date(),
      typeIdentification: userdata[0]['typeIdentification'],
      uid: userdata[0]['uid']
    }

     this.admin.createCertify(data.uid, data).then(function (res) {
      console.log("Document successfully written!");

      load = true;
      console.log(load)
    })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      })
    console.log(load)

  }



  public getReport() {
    const allData = [];
    //, ref => ref.orderBy('uid').startAt('b8qmBdGManeNYfjhBdJwViVuZNP2')
    return this.afs.collection('users').valueChanges()
      .pipe(take(1),
        switchMap(resUser => {
          const dataUser = uniq(resUser.map(res => res))
          /* 			const userId = uniq(resUser.map(res => res['uid'])) */
          return combineLatest(
            of(resUser),
            combineLatest(
              dataUser.map(dataUser =>
                this.afs.collection('enrollment', ref => ref.where('idUser', '==', dataUser['uid'])).valueChanges().pipe(
                  map(enrollment => {
                    enrollment.map(res => {
                      allData.push({
                        iduser: dataUser['uid'],
                        nombre: dataUser['displayName'],
                        correo: dataUser['email'],
                        ID: dataUser['identification'],
                        nameCurso: res['courseName'],
                        progress: res['progress']
                      })
                      console.log(allData.length)
                    })
                  })

                )
              )
            )
          )
        }),
        map(([resUser]) => {
          return allData
        })
      )
  }

  public getUser(id) {
    console.log(id)
    return this.afs.collection('users').doc('' + id + '').valueChanges().pipe(take(1));
  }

  UpdateUser(uid: string, Duser: Partial<xUserBase>) {

    const _data = this.afs.collection("users").doc<xUserBase>(uid).update(Duser);
    return _data
  }

  getFullUser(uid) {
    // console.log(uid)
    return this.afs.collection('users_extra').doc('' + uid + '').valueChanges();


    // let users = this.afs.collection('users');
    // let extraUser = this.afs.collection('users_extra');

    /*     return users.get().pipe(map(res => {
          const uid = res;
          return combineLatest(
            of(res),
            combineLatest(
              this.afs.collection('users_extra').doc('' + uid['uid'] + '').valueChanges().pipe(
                map(extrainfo => {
                  console.log(extrainfo)
                  console.log(uid)
                  data.push({
                    user: uid,
                    extra: extrainfo
                  })
                }))
            )

          )
        })
        ),mapTo (([res])=>{
          return data
        })
     */
  }

  UpdateUserExtra(uid, data) {
    return this.afs.collection('users_extra').doc(uid).set(data)
  }

  



  deleteFields() {

    return this.afs.collection("users", ref => ref.where("gender", "==", "")).valueChanges().pipe(take(1)).subscribe(res => {
      //console.log("res in service")
      res.map((item, index, array) => {
        // console.log(item)
        let prueba = this.afs.collection('users').doc(item['uid']);
        let fieldRm = prueba.update({
          gender: firebase.firestore.FieldValue.delete(),
          organizacion: firebase.firestore.FieldValue.delete()
        })
      })
    })
  }


  convenios() {
    let collection = this.afs.collection('convenios', ref => ref.where('estado','==',true))
    return collection.valueChanges();

  }

  getFaq() {
    return this.afs.collection('faq', ref => ref.orderBy('order')).valueChanges()
  }

  public gatCertificados() {

		return this.afs.collection('certify').valueChanges().pipe(take(1));

	}

  public getMoocs() {

		return this.afs.collection('moocs').valueChanges().pipe(take(1));

	}

  public getConvenioUser() {

		return this.afs.collection('users_extra', ref => ref.where('convenio', '>=', '')).valueChanges().pipe(take(1));

	}


}


