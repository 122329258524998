import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/';

import { StaticRoutingModule } from './static-routing.module';
import { AboutComponent } from './about/about.component';
import { ComponentsModule } from '../components/components.module';
import { FaqComponent } from './faq/faq.component';
import { HelpComponent } from './help/help.component';



@NgModule({
  imports: [SharedModule, StaticRoutingModule, ComponentsModule],
  declarations: [AboutComponent, FaqComponent, HelpComponent]
})
export class StaticModule {}
