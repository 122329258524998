import { Pipe, Component, OnInit } from '@angular/core';
import { ConfigService } from '@app/core/services/config.service';
import {DomSanitizer} from '@angular/platform-browser';
import { RouterService } from '@app/core/router/router.service';


@Component({
  selector: 'anms-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
@Pipe({name: 'safeResourceUrl'})
export class AboutComponent implements OnInit {
  videoUrl:any;

  constructor(
    public config: ConfigService, 
    private sanitizer: DomSanitizer,
    private routerService: RouterService) {
    let url = this.config.checkSchool();
    //console.log(url)

    setTimeout(() => {
      this.config.getSchoolInfo(url['name']).subscribe(res => {
        //console.log(res)
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res['video']);
      })
    }, 200);
  }

  goUrl(path) {
		this.routerService.goRoute(path) 
	}
  ngOnInit() {

  }
}
