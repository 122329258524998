import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material';


@Injectable()
export class NotifyService {
	constructor(
		private matSnackBar: MatSnackBar
	){
		
	}

	public openSnackBar(message, type, time?){
		this.matSnackBar.open(message, type, {
			duration: time ? time : 20000,
			verticalPosition: "bottom",
			horizontalPosition: "right"
		});
	}
// // Mensaje simple.
// let snackBarRef = snackBar.open('Message archived');

// // Mensaje simple con accion.
// let snackBarRef = snackBar.open('Message archived', 'Undo');

// // mensaje con componente.
// let snackBarRef = snackbar.openFromComponent(MessageArchivedComponent);



//   private _msgSource = new Subject<Msg | null>();

//   msg = this._msgSource.asObservable();

//   update(content: string, style: 'error' | 'info' | 'success') {
//     const msg: Msg = { content, style };
//     this._msgSource.next(msg);
//   }

//   clear() {
//     this._msgSource.next(null);
//   }
}