import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MoocErrorMatcher, ValidateService } from '../validate.service';
import { take } from 'rxjs/operators';
import { AuthService } from '../../../core/auth/auth.service';
import { ConfigService } from '@app/core/services/config.service';


export interface identifications {
	value: string;
	viewValue: string;
}
@Component({
	selector: 'step-two-component',
	templateUrl: './step-two.component.html',
	styleUrls: ['./step-two.component.scss'],
})
export class StepTwoComponent {

	StepTwo: FormGroup;
	errorMatcher = new MoocErrorMatcher();
	@Input() uid: string

	convenios: any[]=[];

	convenio: string = '';

	identifications: identifications[] = [
		{ viewValue: 'TI - Tarjeta de identidad', value: 'TI' },
		{ viewValue: 'CC - Cédula de ciudadanía', value: 'CC' },
		{ viewValue: 'CE - Cédula de extranjería', value: 'CE' },
		{ viewValue: 'DNI - Documento Nacional de Identidad', value: 'DNI' },
		{ viewValue: 'CIP - Cédula de Identidad Personal', value: 'CIP' },
		{ viewValue: 'CI - Cédula de Identidad', value: 'CI' },
	];

	
	constructor(
		private formBuilder: FormBuilder,
		private validate: ValidateService,
		private auth: AuthService,
		private confService: ConfigService
	) {
		this.buildForm('', '', null)
	}

	getErrorMessage(control): string {
		return this.validate.ErrorMessage(control)
	}

	ngOnInit() {
		// OBTIENE VALOR DEL USUARIO SI EXISTE, DE LO CONTRARIO ASIGNA EL VALOR POR DEFECTO
		this.auth.GetUserServer(this.uid).pipe(take(1)).subscribe(
			_data => {
				if (_data != undefined) {
					const { typeIdentification, identification, phone } = _data
					this.StepTwo.patchValue({
						typeIdentification, identification, phone
					});
				}
				else{
					const typeIdentification = "CC"
					this.StepTwo.patchValue({
						typeIdentification
					});
				}

			}
		)

		this.getConvenios();
	}

	buildForm(type, ident, telphone) {
		this.StepTwo = this.formBuilder.group({
			typeIdentification: [
				type,
				Validators.required
			],
			identification: [
				ident, [
					Validators.required,
					Validators.minLength(4),
					// Validators.pattern(regExps.password)
				]
			],
			phone: [
				telphone, [
					Validators.required,
					Validators.minLength(7),
					Validators.pattern('[0-9]*')
				]
			],
			convenio: [
				type,
				Validators.required
			],
		})
	}

	getConvenios() {
		this.confService.convenios().subscribe(res => {
		this.convenios = res;
		  console.log('res convenios: ', res);
		})
	}

}
