import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';

import { Store, select } from '@ngrx/store';
import { getIdCurrentUser } from './user.selector';
import { take } from 'rxjs/operators';
import { Enrollment } from './user';
import { AppState } from '../core.state';

export interface userProgress {
	idUser?: string;
	enrollment?: Enrollment;
}

@Injectable()

export class UserService {




	constructor(
		public afs: AngularFirestore,
		private store: Store<AppState>
	) { }
	progressCollection: AngularFirestoreCollection<userProgress>;
	// Agrega el estado inicial de inscripcion a un curso
	addEnrollUserProgress(user, enrollm) {
		const iduser:string = enrollm.idUser
		this.afs.collection('progress').doc(`${iduser}`).set({ iduser });
		return this.afs.collection<Enrollment>('enrollment').doc(`${enrollm.id}`).set(enrollm)
	}

	getEnrollmentsFirestore(idUser) {
		// idUser = 'zGiqJYM3JMMvZ8HXOGVdL6n0OeY2';
		let enrollUser = this.afs.collection('enrollment', ref => ref.where('idUser', '==', `${idUser}`));
		return enrollUser.valueChanges()
	}
	// Actualiza los enrollment

	public UpdateEnrollmenFirestore(id: string, data: Partial<Enrollment>) {
		
		if(id){
			this.afs.collection<Enrollment>('enrollment').doc(id).update(data)
		}else{
			console.log("No fue posible crear el id para el documento")
		}

	}

}
