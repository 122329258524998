

import { Directionality } from '@angular/cdk/bidi';
import {
	CdkStepper,
	StepContentPositionState,
	CdkStepHeader,
	CdkStep,
} from '@angular/cdk/stepper';
import { AnimationEvent } from '@angular/animations';
import {
	AfterContentInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChildren,
	QueryList,
	ContentChildren,
	Inject,
	forwardRef,
	SkipSelf,
	Optional,
	ChangeDetectionStrategy,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';

// import {MatStepHeader} from './step-header';
import { animations } from './animation';
import { NavigatorComponent } from './navigator/navigator.component';
import { ErrorStateMatcher } from '@angular/material';

import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';



// @Directive({ selector: '[matStepper]', providers: [{ provide: CdkStepper, useExisting: NavigatorComponent }] })
// export class MatStepper extends CdkStepper implements AfterContentInit {
//   /** The list of step headers of the steps in the stepper. */

// }

@Component({
	selector: 'mooc-step',
	template: '<ng-template><ng-content></ng-content></ng-template>',
	providers: [{
		provide: ErrorStateMatcher,
		useExisting: MoocStepComponent
	}],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class MoocStepComponent extends CdkStep implements ErrorStateMatcher {
	/** Content for step label given by `<ng-template matStepLabel>`. */
	// @ContentChild(MatStepLabel, {static: false}) stepLabel: MatStepLabel;

	/** @breaking-change 8.0.0 remove the `?` after `stepperOptions` */
	constructor(@Inject(forwardRef(() => StepperSliderComponent)) stepper: StepperSliderComponent,
		@SkipSelf() private _errorStateMatcher: ErrorStateMatcher,
	) {
		super(stepper);
	}

	/** Custom error state matcher that additionally checks for validity of interacted form. */
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const originalErrorState = this._errorStateMatcher.isErrorState(control, form);

		// Custom error state checks for the validity of form that is not submitted or touched
		// since user can trigger a form change by calling for another step without directly
		// interacting with the current form.
		const customErrorState = !!(control && control.invalid && this.interacted);

		return originalErrorState || customErrorState;
	}
}






@Component({
	selector: 'mooc-stepper-slider',
	templateUrl: './stepper-slider.component.html',
	styleUrls: ['./stepper-slider.component.scss'],
	providers: [{
		provide: CdkStepper, useExisting: StepperSliderComponent
	},

	],
	inputs: ['selectedIndex'],
	host: {
		// 'class': 'mat-stepper-horizontal',
		// 'aria-orientation': 'horizontal',
		'role': 'tablist',
	},
	animations: [animations.horizontalStepTransition],
})

export class StepperSliderComponent extends CdkStepper implements AfterContentInit {
	constructor(dir: Directionality, changeDetectorRef: ChangeDetectorRef) {
		super(dir, changeDetectorRef);
	}

	@ViewChildren(NavigatorComponent) _stepHeader: QueryList<CdkStepHeader>;
	// CdkStepHeader

	// /** Steps that the stepper holds. */
	@ContentChildren(MoocStepComponent) _steps: QueryList<CdkStep>;


	/** Event emitted when the current step is done transitioning in. */
	@Output() readonly animationDone: EventEmitter<void> = new EventEmitter<void>();

	/** Whether ripples should be disabled for the step headers. */
	@Input() disableRipple: boolean;



	/** Stream of animation `done` events when the body expands/collapses. */
	_animationDone = new Subject<AnimationEvent>();

	ngAfterContentInit() {
		// Mark the component for change detection whenever the content children query changes
		this._steps.changes.pipe(takeUntil(this._destroyed)).subscribe((w) => {
			this._stateChanged()
		}
		);

		this._animationDone.pipe(
			// This needs a `distinctUntilChanged` in order to avoid emitting the same event twice due
			// to a bug in animations where the `.done` callback gets invoked twice on some browsers.
			// See https://github.com/angular/angular/issues/24084
			distinctUntilChanged((x, y) => x.fromState === y.fromState && x.toState === y.toState),
			takeUntil(this._destroyed)
		).subscribe(event => {
			if ((event.toState as StepContentPositionState) === 'current') {
				this.animationDone.emit();
			}
		});
	}

	startAnimation(event) {
		switch (event) {
			case 'left':
				this.next()
				break;
			case 'right':
				this.previous()

				break;

			default:
				break;
		}
	}

}
