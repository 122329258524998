import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';


import { select, Store, Action } from '@ngrx/store';
import { UserActionTypes, GetUser, Authenticated, NotAuthenticated, AuthError, UserLoad, GetEnrollments, loadEnrollments, UpdateEnrollment, AddOneEnrollment, CreateEnrollment, Logout, deleteUser } from '@app/core/user/user.actions';
import { map, tap, switchMap, delay, catchError, withLatestFrom, mergeMap, take, filter, mergeAll, } from 'rxjs/operators';
import { UserService } from '@app/core/user/user.service';
import { Observable, of, combineLatest } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { User, Enrollment } from './user';
import { Router } from '@angular/router';
import { NotifyService } from '@app/core/notify-service/notify.service';
import { RequestedAllScores } from '@app/courses/core/user/user-score.actions';
import { getIdCurrentUser, selectEnrollmentsIds, EnrollmentLoaded, selectEnrollmentsByCourse, selectEnrollments } from '@app/core/user/user.selector';
import { AppState } from '../core.state';
import { AngularFirestore } from '@angular/fire/firestore';
import { ConfigService } from '../services/config.service';



@Injectable()
export class UserEffects {
	constructor(
		private actions$: Actions,
		private store: Store<AppState>,
		private userService: UserService,
		private afAuth: AngularFireAuth,
		private router: Router,
		private notify: NotifyService,
		public afs: AngularFirestore,
		public config: ConfigService
	) { }

	@Effect()
	getUser = this.actions$
		.pipe(
			ofType<GetUser>(UserActionTypes.GET_USER),
			map((action) => action.payload),
			switchMap(payload => this.afAuth.authState),
			// delay(1000),
			map(authData => {
				if (authData) {
					const user = new User(
						authData.uid,
						authData.displayName,
						authData.email,
						authData.emailVerified,
					);
					return new UserLoad({ user });
				} else {
					// /// Usuario no autenticado
					return new NotAuthenticated();
				}
			}),
			catchError(err => of(new AuthError({ error: err.message })))
		);

	@Effect({ dispatch: false })
	addUser$ = this.actions$
		.pipe(
			ofType<UserLoad>(UserActionTypes.USER_LOAD),
			// Solicita los progresos por usuario
			tap(user => this.store.dispatch(new RequestedAllScores(user.payload.user.uid))),
			// Solicita los enrollments por usuario
			tap(data => this.store.dispatch(new GetEnrollments(data.payload.user.uid))),
		);



	@Effect({ dispatch: false })
	AddOneEnrollment(): Observable<any> {
		return this.actions$
			.pipe(
				ofType<AddOneEnrollment>(UserActionTypes.ADD_ONE_ENROLLMENT),
				// Obtener los id enrollments
				withLatestFrom(
					this.store.pipe(select(selectEnrollmentsIds)),
					this.store.pipe(select(getIdCurrentUser))
				),
				tap(([enroll, enrollments, idUser]) => {
					// filtar si fue creado el elemento
					var [isCreate] = enrollments.filter(selec => selec === enroll.courseId);
					if (isCreate) {
					} else {
					
						var enrollment = {
							courseName: enroll.payload.courseName,
							courseId: enroll.payload.courseId,
							coursePath: enroll.payload.coursePath,
							createdAt: new Date(),
							lastUpdate: new Date(),
							progress: 0,
							idUser: idUser,
							lastLection: {
								title: enroll.payload.firstlesson.title,
								path: enroll.payload.firstlesson.path,
								order: enroll.payload.firstlesson.order
							},
							id: this.afs.createId(),
							institucion: this.config.checkSchool()['name']
						}
						// Crear por primera vez el enrollment
						this.store.dispatch(new CreateEnrollment({ enrollment }))
					}
				})
			);
	}


	@Effect({ dispatch: false })
	createEnrollment$ = this.actions$
		.pipe(
			ofType<CreateEnrollment>(UserActionTypes.CREATE_ENROLLMENT),
			tap((value) => {
				if (value.payload.enrollment.idUser != undefined) {
					this.userService.addEnrollUserProgress(`${value.payload.enrollment.idUser}`, value.payload.enrollment);
				}
			}),
		);


	@Effect({ dispatch: false })
	getEnrollments$ = this.actions$
		.pipe(
			ofType<GetEnrollments>(UserActionTypes.GET_ENROLLMENTS),
			withLatestFrom(this.store.pipe(select(EnrollmentLoaded))),
			filter(([action, loaded]) => !loaded),
			mergeMap(([userUid]) => this.userService.getEnrollmentsFirestore(userUid.idUser)),
			map(scores => {

				if (scores != undefined) {
					var keys = Object.keys(scores)
					let enrollment = keys.map(i => scores[i]).filter(d => d.courseId);
					this.store.dispatch(new loadEnrollments({ enrollment }))
				}
			}),
		);





	@Effect({ dispatch: false })
	loadEnrollments$ = this.actions$
		.pipe(
			ofType<loadEnrollments>(UserActionTypes.LOAD_ENROLLMENTS),
		);




	@Effect({ dispatch: false })
	UpdateEnrollment$ = this.actions$
		.pipe(
			ofType<UpdateEnrollment>(UserActionTypes.UPDATE_ENROLLMENT),
			map(data => {
				this.store.pipe(
					select(selectEnrollmentsByCourse(data.id)),
					take(1),
				).subscribe(enroll => {
					if (enroll != undefined) {
						this.userService.UpdateEnrollmenFirestore(enroll.id, data.changes)
					}

				})
			}),
		);




	@Effect()
	logout = this.actions$
		.pipe(
			ofType<Logout>(UserActionTypes.LOGOUT),
			map((action) => action.payload),
			switchMap(payload => this.afAuth.auth.signOut()),
			map(authData => {
				// /// Usuario no autenticado

				localStorage.clear();
				this.store.dispatch(new deleteUser)
				this.router.navigate(['/']);
				this.notify.openSnackBar('Cerraste sesión correctamente', 'Continuar');
				return new NotAuthenticated();
			}),
			catchError(err => of(new AuthError({ error: err.message })))
		);
}








