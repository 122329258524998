import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { take, finalize, tap, map, switchMap } from 'rxjs/operators';
import { Observable, combineLatest, of } from 'rxjs'
import { uniq, flatten } from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  datos: any[];
  constructor(public afs: AngularFirestore) { }

  public getUsers() {
    return this.afs.collection('users', ref => ref.where('uid', '!=' , null)).valueChanges().pipe(take(1));
  }
  public getMoocs() {
    return this.afs.collection('moocs').valueChanges().pipe();
  }

  public getEnrollment() {
    return this.afs.collection('enrollment').valueChanges().pipe(take(1));
  }

  public getCertify() {
    return this.afs.collection('certify').valueChanges().pipe(take(1));
  }

  public insertRDB() {
    /*     var db = admin.database();
        let ref = db.ref('mirror-mooc-233515-59c10-admin')
        ref.set({
          username: 'arismel',
          email: 'aris@gmail.com'
        },
          function (error) {
            if (error) {
              // The write failed...
              console.log('error al insertar')
            } else {
              console.log('Data saved successfully!')
              // Data saved successfully!
            }

          }); */
  }

  insertMooc(moocData: any, update: boolean) {

    // if(update){
    //   var moocId:any = moocData.idCourse;
    // } else {
    //   // Crea una referencia a la colección 'moocs' sin especificar el ID del documento
    //   var moocId:any = this.afs.createId();
    // }
    const moocId = update == true ? moocData.idCourse : this.afs.createId();
    const moocCollectionRef = this.afs.collection('moocs').doc(moocId);
    
    // Agrega el ID del curso a los datos del MOOC
    moocData.idCourse = moocId;
  
    // Utiliza 'set' para insertar el MOOC con el ID generado automáticamente
    return moocCollectionRef.set(moocData)
      .then(() => {
        // Si la inserción tiene éxito, resuelve la promesa con el ID
        return moocId;
      })
      .catch(error => {
        console.error("Error inserting MOOC: ", error);
        throw error;
      });
  }

  // insertMaterial(moocId: string, leccionId: string, material: any) {
  insertMaterial(moocId: string, material: any) {
    const materialCollectionRef = this.afs.collection('moocs').doc(moocId).collection('resources').doc(material.id);
    return materialCollectionRef.set(material);
  }

  insertLeccion(moocId: string, leccion: any) {

    // Crea una referencia a la subcolección 'lections' dentro del documento de MOOC
    const leccionesCollectionRef = this.afs.collection('moocs').doc(moocId).collection('lections').doc(leccion.id);
  
    // Utiliza 'add' para insertar la lección sin especificar el ID del documento
    // return leccionesCollectionRef.add({ ...leccion, id: leccionId });
    return leccionesCollectionRef.set(leccion);
  }

   // inserta en la colección de recursos
    insertResource(resource: any) {
      const materialCollectionRef = this.afs.collection('recursos').doc(resource.id);
      return materialCollectionRef.set(resource);
    }



  getUserByEmail(email) {
    return this.afs.collection("users", ref => ref.where("email", "==", "" + email + "")).valueChanges().pipe(take(1))
  }

  getUserByIdOne(uid) {
    return this.afs.collection("users", ref => ref.where("uid", "==", "" + uid + "")).valueChanges().pipe(take(1))
  }

  getCourseById(id) {
    return this.afs.collection("moocs", ref => ref.where("idCourse", "==", "" + id + "")).valueChanges().pipe(take(1))
  }

  createCertify(uid, data) {
    //console.log(uid, data)
    return this.afs.collection('certify').doc(uid + data.courseId).set(data)
  }


  public getEnrollmentsMCIEN() {
    return this.afs.collection('enrollment', ref => ref.where("progress", ">", 100)).valueChanges()
  }

  public getUserById(id) {
    return this.afs.collection('users', ref => ref.where("uid", "==", '' + id + '')).valueChanges()
  }

  getLessonsByidCourse(idCourse) {
    return this.afs.collection('moocs').doc('' + idCourse + '').collection('lections').valueChanges().pipe(take(1))
  }

  getDetalleMCIEN(item) {
    return this.afs.collection('progress').doc('' + item['idUser'] + '')
      .collection('progressState', ref =>
        ref.where('idCourse', '==', '' + item['idCurso'] + '')).valueChanges().pipe(take(1))
  }

  getScoreMCIEN(item) {
    return this.afs.collection('progress').doc('' + item['item']['idUser'] + '').collection('score', ref =>
      ref.where('idCourse', '==', '' + item['detalle'][0]['idCourse'] + '')).valueChanges().pipe(take(1))
  }

  getCoursesbyEmail(email) {

    const allData = [];
    //, ref => ref.orderBy('uid').startAt('b8qmBdGManeNYfjhBdJwViVuZNP2')
    return this.afs.collection('users', ref => ref.where("email", "==", "" + email + "")).valueChanges()
      .pipe(take(1),
        switchMap(resUser => {
          const dataUser = uniq(resUser.map(res => res))
          return combineLatest(
            of(resUser),
            combineLatest(
              dataUser.map(dataUser =>
                this.afs.collection('enrollment', ref => ref.where('idUser', '==', dataUser['uid'])).valueChanges().pipe(
                  map(enrollment => {
                    enrollment.map(res => {
                      allData.push({
                        id: res['id'],
                        iduser: dataUser['uid'],
                        nombre: dataUser['displayName'],
                        correo: dataUser['email'],
                        //identification: dataUser['identification'],
                        courseName: res['courseName'],
                        progress: res['progress'],
                        courseId: res['courseId']
                      })
                      //console.log(res)
                    })
                  })

                )
              )
            )
          )
        }),
        map(([resUser]) => {
          return allData
        })
      )

  }

  async fixProgreso(id, data) {
    //return this.afs.collection('enrollment').doc(id).set(data)

    const enrollRef = this.afs.collection('enrollment').doc(id);

    const res = await enrollRef.update({ progress: 100 });

    return res;
  }

  existCertify(uid) {
    return this.afs.collection('certify', ref => ref.where('uid', '==', '' + uid + '')).valueChanges().pipe(take(1))
  }

  getCertifyEmail(email) {
    return this.afs.collection('certify', ref => ref.where('email', '==', '' + email + '')).valueChanges().pipe(take(1))
  }

  editarCertificadoMasivo(courseId, nombreCurso) {
    //console.log(uid, data)
    // var courseId = 'qQGc7BSCmQgmNeKm20do';
    
      const collectionRef = this.afs.collection('certify', ref => ref.where('courseId', '==', courseId));
  
      collectionRef.get().subscribe(querySnapshot => {
        querySnapshot.forEach(doc => {
          const docRef = this.afs.collection('certify').doc(doc.id);
          docRef.update({ courseName: nombreCurso })
            .then(() => {
              console.log('Documento actualizado con éxito.');
              
            })
            .catch(error => {
              console.error('Error al actualizar el documento:', error);
              
            });
        });
      });

  }

  editarCertificadoNombreUsuario(email, nombreUsuario) {
    //console.log(email, nombreUsuario)
    
    
      const collectionRef = this.afs.collection('certify', ref => ref.where('email', '==', email));
  
      collectionRef.get().subscribe(querySnapshot => {
        querySnapshot.forEach(doc => {
          const docRef = this.afs.collection('certify').doc(doc.id);
          docRef.update({ displayName: nombreUsuario })
            .then(() => {
              console.log('Documento actualizado con éxito.');
              
            })
            .catch(error => {
              console.error('Error al actualizar el documento:', error);
              
            });
        });
      });

  }


}
