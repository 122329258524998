import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsComponent } from './settings';
import { XguardService, XloginGuard } from '@area/user';
import { FaqComponent } from './static/faq/faq.component';
import { HelpComponent } from './static/help/help.component';
import { CertificadoComponent } from './certificado/certificado/certificado.component';
import { FormMoocComponent } from './form-mooc/form-mooc.component';



const routes: Routes = [
	{
		path: '',
		redirectTo: 'inicio',
		pathMatch: 'full'
	},
	{ path: 'crear-mooc', 
		component: FormMoocComponent 
	},
	{ path: 'editar-mooc/:id', 
		component: FormMoocComponent 
	},
	{
		path: "faq",
		component: FaqComponent,
	},
	{
		path: "help",
		component: HelpComponent,
	},
	{
		path: 'login',
		// loadChildren: 'app/login/login.module#LoginModule',
		loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
		canActivate: [XloginGuard]
	},
	{
		path: 'configuracion',
		component: SettingsComponent,
		data: {
			title: 'Configuracion'
		}
	},
	{
		path: 'cursos',
		loadChildren: () => import('./courses/courses.module').then(m => m.CoursesModule),
	},
	{
		path: 'usuario',
		loadChildren: () => import('./profiles/profiles.module').then(m => m.ProfilesModule),
		canActivate: [XguardService],
		data: {
			title: 'Usuario'
		}
	},
	{
		path: 'certificados',
		loadChildren: () => import('./certificado/certificado.module').then(m => m.CertificadoModule),
		data: {
			title: 'Certificados'
		}
    },
	{
		path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [XguardService],
		data: {
			title: 'admin'
		}
  },
  {
		path: 'editProfile',
    loadChildren: () => import('./profiles/edit-profile/editProfile.module').then(m => m.EditProfileModule),
    canActivate: [XguardService],
		data: {
			title: 'admin'
		}
  },
	{
		path: '**',
		redirectTo: 'inicio'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: [XguardService, XloginGuard],
})
export class AppRoutingModule { }
