import { Title } from '@angular/platform-browser';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, OnDestroy, OnInit, HostListener } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject, Observable } from 'rxjs';
import { takeUntil, filter, map, finalize } from 'rxjs/operators';

import { ActivationEnd, Router, NavigationEnd, ActivationStart } from '@angular/router';
// import { ActivatedRoute, Router} from '@angular/router';

//iconos personalizados
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

// Notify

import { NotifyService } from '../app/core/notify-service/notify.service';
// animation
import { trigger, style, animate, transition, state } from '@angular/animations';
import {
	//   ActionAuthLogin,
	//   ActionAuthLogout,
	//   selectorAuth,
	AnimationsService,
	routeAnimations
} from '@app/core';
import { environment as env } from '@env/environment';


import {
	NIGHT_MODE_THEME,
	selectorSettings,
	SettingsState,
} from './settings';

import { SwUpdate } from "@angular/service-worker";
import { AppState } from './core/core.state';
import { SelectSidenavMenu } from './core/interfaz/interfaz.selector';
import { UpdateInterfaz } from './core/interfaz/interfaz.actions';
import { MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { ModalIdentificationComponent } from './ui/modal-identification/modal-identification.component';
import { AuthService } from './core/auth/auth.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [
		routeAnimations,
		trigger('enterAnimation', [
			transition('void => *', [
				style({
					opacity: 0,

				}),
				animate('0.5s ease-in-out')
			]),
			state('in', style({ opacity: '*' })),
			transition('* => void', [
				style({ opacity: '*' }),
				animate(400, style({ opacity: 0 }))
			])

		]),
	]
})


export class AppComponent implements OnInit, OnDestroy {
	authenticated: boolean = false;
	private unsubscribe$: Subject<void> = new Subject<void>();
	sidenavToggle$: Observable<boolean>;
	editPopupRef: MatDialogRef<ModalIdentificationComponent>;
	// @HostListener('panright')
	// openSidenav() {
	// 	console.log("right")
	// }

	// @HostListener('panleft')
	// closeSidenav() {
	// 	console.log("left")
	// }

	@HostBinding('class') componentCssClass;

	isProd = env.production;
	year = new Date().getFullYear();
	hideHeader: boolean = false;
	progressUser: any;


	constructor(
		private swUpdate: SwUpdate,
		public overlayContainer: OverlayContainer,
		private store: Store<AppState>,
		private router: Router,
		// private activeRoute: ActivatedRoute,
		private titleService: Title,
		public notify: NotifyService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private animationService: AnimationsService,
		public dialog: MatDialog,
		private auth: AuthService,
		private _snackBar: MatSnackBar
	) {
		// this.notify.msg.pipe(takeUntil(this.unsubscribe$)).subscribe(message => {
		// 	matSnackBar.open(message.content, "ok", {
		// 		duration: 2000,
		// 		verticalPosition: "bottom",
		// 		horizontalPosition: "right"
		// 	});
		// });
		//iconos personalizados
		this.matIconRegistry.addSvgIcon(
			`icon_logo`,
			this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/iconos/icon-logo.svg")
		);
		this.matIconRegistry.addSvgIcon(
			`icon_a`,
			this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/iconos/icon-a.svg")
		);
		this.matIconRegistry.addSvgIcon(
			`icon_login`,
			this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/iconos/icon-login.svg")
		);


    this.checkCompatibilidad();


	}
	completeReg($event) {
		this.authenticated = $event
	}
	errorHandle(error) {
		this.notify.openSnackBar(error.message, 'Aceptar');
	}

	ngOnInit() {
		this.auth.isNew().subscribe(data => {
			this.isNew(data.payload)
		})
		this.auth.initUser()
		this.initUser();
		// Escucha los cambios en la versión de la aplicación y pregunta al usuario si desea actualizar
		this.updateApp()
		//
		this.subscribeToSettings();
		this.subscribeToRouterEvents();

		// Escucha los eventos que deben ser emitidos al sidenav
		this.sidenavToggle$ = this.store.pipe(
			select(SelectSidenavMenu),
			map(data => data.state)
		)

	}
	initUser() {
		// Obtener los datos de usuario
		// this.auth.user.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
		// 	if (data != null && data.emailVerified) this.isNew(data);
		// })
	}

	isNew(is) {
		if (is) {
			this.editPopupRef = this.dialog.open(ModalIdentificationComponent, {
				minWidth: '100%',
				minHeight: '100%',
				width: '100%',
				height: '100%',
				hasBackdrop: true,
				disableClose: true,
				closeOnNavigation: false,
				panelClass: 'modal-identification',
				data: {
					...is
				}
			});
			this.editPopupRef
				.afterClosed().subscribe(
					userForm => {

						this.auth.CreateUserServer(userForm)
						setTimeout(() => { this.notify.openSnackBar('Bienvenido a MOOC', 'Entendido') }, 900);
					}
				);
		}

	}
	closeSideNav($event) {
		this.store.dispatch(new UpdateInterfaz("sidenavMenu", {
			state: $event
		}));
	}

	updateApp() {
		if (this.swUpdate.isEnabled) {
			this.swUpdate.available.subscribe(next => {
				console.log("NUEVA VERSION ")
				this._snackBar.open('Nueva versión disponible. ¿Deseas actualizar ahora?', 'Actualizar', {
					horizontalPosition: 'center',
					verticalPosition: 'top',
				}).afterDismissed().subscribe(() => {
					window.location.reload()
				});
			})
		}
	}
	private subscribeToSettings() {
		// if (AppComponent.isIEorEdge()) {
		// 	this.store.dispatch(
		// 		new ActionSettingsChangeAnimationsPageDisabled({
		// 			pageAnimationsDisabled: true
		// 		})
		// 	);
		// }
		this.store
			.select(selectorSettings)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(settings => {
				this.setTheme(settings);
				this.animationService.updateRouteAnimationType(
					settings.pageAnimations,
					settings.elementsAnimations
				);
			});
	}

	private setTheme(settings: SettingsState) {
		const { theme, autoNightMode } = settings;
		const hours = new Date().getHours();
		const effectiveTheme = (autoNightMode && (hours >= 20 || hours <= 6)
			? NIGHT_MODE_THEME
			: theme
		).toLowerCase();
		this.componentCssClass = effectiveTheme;
		const classList = this.overlayContainer.getContainerElement().classList;
		const toRemove = Array.from(classList).filter((item: string) =>
			item.includes('-theme')
		);
		if (toRemove.length) {
			classList.remove(...toRemove);
		}
		classList.add(effectiveTheme);
	}

	private subscribeToRouterEvents() {
		this.router.events
			.pipe(
				filter(
					event =>
						event instanceof ActivationEnd || event instanceof NavigationEnd || event instanceof ActivationStart
				),
				takeUntil(this.unsubscribe$)
			)
			.subscribe(event => {
				if (event instanceof ActivationEnd) {
					this.setPageTitle(event);
				}
			});
	}

	private setPageTitle(event: ActivationEnd) {
		let lastChild = event.snapshot;

		while (lastChild.children.length) {
			// console.log("event route: NavigationEnd === while", event);
			lastChild = lastChild.children[0];
		}

		// if (event.snapshot.params.lesson) {
		// 	this.hideHeader = true;
		// } else {
		// 	this.hideHeader = false;
		// }
		const title = lastChild.data.lesson ? lastChild.data.lesson.title : '';

		this.titleService.setTitle(
			title ? `${title} - ${env.appName}` : env.appName
		);
	}
	// oculta el header en el componente de curso
	// Se configuro desde la vista en remplazo de esta funcion
	// private navigationStart(event: ActivationStart) {
	// 	if (event.snapshot.params.lesson) {
	// 		this.hideHeader = true;
	// 	} else {
	// 		this.hideHeader = false;
	// 	}
	// }

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();

	}

  checkCompatibilidad() {

    if ('serviceWorker' in navigator && 'PushManager' in window) {
      //console.log('Service Worker and Push is supported');

      navigator.serviceWorker.register('/ngsw-worker.js')
        .then((swReg) => {
          //console.log('Service Worker is registered', swReg);

        })
        .catch(function (error) {
          //console.log('Service Worker Error', error);
        });
    } else {
     // console.log('Push messaging is not supported');
    }

  }

}
