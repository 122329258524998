import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromActionInterfaz from './interfaz.reducer';



export const selectActionUi = createFeatureSelector<fromActionInterfaz.State>("interfaz");





export const SelectSidenavMenu = createSelector(
	selectActionUi,
	data => data.entities['sidenavMenu']
);

// export const playPause = createSelector(
// 	selectActionUi,
// 	coursesState => coursesState.entities['playPause']
// );

// export const escenaActual = createSelector(
// 	selectActionUi,
// 	coursesState => coursesState.entities['escenaActual']
// );