import {FocusMonitor} from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,

  Component,
  ElementRef,
  Input,
  OnDestroy,

} from '@angular/core';
import {Subscription} from 'rxjs';
import {CdkStepHeader, StepState} from '@angular/cdk/stepper';

@Component({
  selector: 'mooc-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss'],
  host: {
    'class': 'mooc-stepper-navigator',
    'role': 'tab',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigatorComponent extends CdkStepHeader implements OnDestroy{
  // private _intlSubscription: Subscription;

  /** State of the given step. */
  @Input() state: StepState;



  // /** Error message to display when there's an error. */
  // @Input() errorMessage: string;


  /** Index of the given step. */
  @Input() index: number;

  /** Whether the given step is selected. */
  @Input() selected: boolean;

  /** Whether the given step label is active. */
  @Input() active: boolean;

  // /** Whether the given step is optional. */
  // @Input() optional: boolean;

  // /** Whether the ripple should be disabled. */
  // @Input() disableRipple: boolean;

  constructor(
    private _focusMonitor: FocusMonitor,
    _elementRef: ElementRef<HTMLElement>,
) {
    super(_elementRef);
    _focusMonitor.monitor(_elementRef, true);

    }
    ngOnDestroy() {
      // this._intlSubscription.unsubscribe();
      this._focusMonitor.stopMonitoring(this._elementRef);
    }
  
    /** Focuses the step header. */
    focus() {
      this._focusMonitor.focusVia(this._elementRef, 'program');
    }
  
    /** Returns string label of given step if it is a text label. */
   
  
    /** Returns MatStepLabel if the label of given step is a template label. */
  
    /** Returns the host HTML element. */
    _getHostElement() {
      return this._elementRef.nativeElement;
    }
 
  
    _getDefaultTextForState(state: StepState): string {
      if (state == 'number') {
        return `${this.index + 1}`;
      }
      if (state == 'edit') {
        return 'create';
      }
      if (state == 'error') {
        return 'warning';
      }
      return state;
    }

  ngOnInit() {
  }

}
