import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { User } from './user';
import { UserActions, UserActionTypes } from './user.actions';




export interface UserState extends EntityState<User> {
	loading: boolean;
}

// function sortByCourseAndSeqNo(l1: Lesson, l2: Lesson) {
// 	const compare = l1.order - l2.order;
// 	if (compare != 0) {
// 		return compare;
// 	}
// 	else {
// 		return l1.order;
// 	}
// }


export const Useradapter: EntityAdapter<User> =
	createEntityAdapter<User>({
		selectId: user => user.uid ? user.uid : "enrollment",
		// sortComparer: sortByCourseAndSeqNo
	});


const initialUserState = Useradapter.getInitialState({
	loading: false
});


export function UserReducer(state = initialUserState,
	action: UserActions): UserState {

	switch (action.type) {
		case UserActionTypes.GET_USER:
			return { ...state, loading: true };

		case UserActionTypes.AUTH_ERROR:
			return { ...state, ...action.payload, loading: false };

		case UserActionTypes.USER_LOAD:
			return Useradapter.addOne(action.payload.user, { ...state, loading: false });

		case UserActionTypes.NOT_AUTHENTICATED:
			return { ...state, loading: false };

		case UserActionTypes.DELETE_USER:
			return Useradapter.removeAll(state);

		// case UserActionTypes.ADD_ENROLLMENT:
		// 	return Useradapter.updateOne(action.changes.enrollment, state);



		// loadEnrollments



		default:
			return state;

	}
}



export const {
	selectAll,
	selectEntities,
	selectIds,
	selectTotal

} = Useradapter.getSelectors();




// export type Action = userActions.All;

// const defaultUser = new User(null, 'GUEST');

/// Reducer function
// export function userReducer(state: User = defaultUser, action: Action) {
// 	switch (action.type) {

// 		case UserActionTypes.GET_USER:
// 			return { ...state, loading: true };

// 		case UserActionTypes.AUTHENTICATED:
// 			return { ...state, ...action.payload, loading: false };

// 		case UserActionTypes.NOT_AUTHENTICATED:
// 			return { ...state, ...defaultUser, loading: false };

// 		case UserActionTypes.GOOGLE_LOGIN:
// 			return { ...state, loading: true };

// 		case UserActionTypes.AUTH_ERROR:
// 			return { ...state, ...action.payload, loading: false };

// 		case UserActionTypes.LOGOUT:
// 			return { ...state, loading: true };

// 	}
// }


