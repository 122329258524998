import { Component, OnInit } from '@angular/core';
import { RouterService } from '@app/core/router/router.service';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/core.state';
import { UpdateInterfaz } from '@app/core/interfaz/interfaz.actions';

@Component({
	selector: 'mooc-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	toogle:boolean = false;

	constructor(
		private routerService: RouterService,
		private store: Store<AppState>
	) { }

	ngOnInit() {

	}
	goRoute(path) {
		this.routerService.goRoute(path)
	}
	openSidenav($event) {
		const toogle = $event
		this.store.dispatch(new UpdateInterfaz("sidenavMenu", {
			state: toogle
		}));
	}
}
