import { Component, Input, AfterViewInit, AfterViewChecked} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MoocErrorMatcher } from '../validate.service';



@Component({
	selector: 'step-one-component',
	templateUrl: './step-one.component.html',
	styleUrls: ['./step-one.component.scss'],
})
export class StepOneComponent implements AfterViewChecked {
	StepOne: FormGroup;
	errorMatcher = new MoocErrorMatcher();
	@Input() name:string = ''

	constructor(private formBuilder: FormBuilder) {
		this.StepOne = this.formBuilder.group({});
	}

	ngOnInit() {
	
		this.StepOne = this.formBuilder.group({
			displayName: [this.name, [
				Validators.required, 
				Validators.minLength(9),
				Validators.maxLength(64)
			]]
			
		});
	}
	ngAfterViewChecked(){
	
	}


}
