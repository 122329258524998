import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Interfaz } from './interfaz.model';

export enum InterfazActionTypes {
	LoadInterfazs = '[Interfaz] Load Interfazs',
	AddInterfaz = '[Interfaz] Add Interfaz',
	UpsertInterfaz = '[Interfaz] Upsert Interfaz',
	AddInterfazs = '[Interfaz] Add Interfazs',
	UpsertInterfazs = '[Interfaz] Upsert Interfazs',
	UpdateInterfaz = '[Interfaz] Update Interfaz',
	UpdateInterfazs = '[Interfaz] Update Interfazs',
	DeleteInterfaz = '[Interfaz] Delete Interfaz',
	DeleteInterfazs = '[Interfaz] Delete Interfazs',
	ClearInterfazs = '[Interfaz] Clear Interfazs'
}

export class LoadInterfazs implements Action {
	readonly type = InterfazActionTypes.LoadInterfazs;

	constructor(public payload: { interfazs: Interfaz[] }) { }
}

export class AddInterfaz implements Action {
	readonly type = InterfazActionTypes.AddInterfaz;

	constructor(public payload: { interfaz: Interfaz }) { }
}

export class UpsertInterfaz implements Action {
	readonly type = InterfazActionTypes.UpsertInterfaz;

	constructor(public payload: { interfaz: Interfaz }) { }
}

export class AddInterfazs implements Action {
	readonly type = InterfazActionTypes.AddInterfazs;

	constructor(public payload: { interfazs: Interfaz[] }) { }
}

export class UpsertInterfazs implements Action {
	readonly type = InterfazActionTypes.UpsertInterfazs;

	constructor(public payload: { interfazs: Interfaz[] }) { }
}

export class UpdateInterfaz implements Action {
	readonly type = InterfazActionTypes.UpdateInterfaz;

	constructor(
		public id: string,
		public changes: Partial<any>,
	) { }
}

export class UpdateInterfazs implements Action {
	readonly type = InterfazActionTypes.UpdateInterfazs;

	constructor(public payload: { interfazs: Update<Interfaz>[] }) { }
}

export class DeleteInterfaz implements Action {
	readonly type = InterfazActionTypes.DeleteInterfaz;

	constructor(public payload: { id: string }) { }
}

export class DeleteInterfazs implements Action {
	readonly type = InterfazActionTypes.DeleteInterfazs;

	constructor(public payload: { ids: string[] }) { }
}

export class ClearInterfazs implements Action {
	readonly type = InterfazActionTypes.ClearInterfazs;
}

export type InterfazActions =
	LoadInterfazs
	| AddInterfaz
	| UpsertInterfaz
	| AddInterfazs
	| UpsertInterfazs
	| UpdateInterfaz
	| UpdateInterfazs
	| DeleteInterfaz
	| DeleteInterfazs
	| ClearInterfazs;
