// import { AuthService } from '../../core/auth/auth.service';

import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
	selectorSettings,
	ActionSettingsChangeTheme,
	SettingsState,
	ActionSettingsPersist
} from '../../settings/settings.reducer';

@Component({
  selector: 'mooc-button-theme',
  templateUrl: './button-theme.component.html',
  styleUrls: ['./button-theme.component.scss']
})
export class ButtonThemeComponent implements OnInit {
	private unsubscribe$: Subject<void> = new Subject<void>();
	settings: SettingsState;
	checked: boolean;
	// photoURL = this.auth.photoURL;

	constructor(
		// public auth: AuthService,
		private store: Store<any>
	) {
		store
			.select(selectorSettings)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(settings => (
				this.settings = settings,
				this.actualTheme()
			));
	}

	// logout() {
	// 	this.auth.signOut();
	// }

	ngOnInit() {
		this.actualTheme();
	}
	actualTheme() {
		switch (this.settings.theme) {
			case 'LIGHT-THEME':
				this.checked = false
				break;
			case 'BLACK-THEME':
				this.checked = true
				break;
		}
	}
	onThemeSelect({ theme }) {
		this.store.dispatch(new ActionSettingsChangeTheme({ theme }));
		this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
	}

	selectTheme($event) {
		
		this.checked = !this.checked;
		if (this.checked === false) {
			this.onThemeSelect({ theme: 'LIGHT-THEME' })
		} else {
			this.onThemeSelect({ theme: 'BLACK-THEME' })
		}
		

	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

}



