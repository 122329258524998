// import { Component, OnInit, OnDestroy } from '@angular/core';
// import { Store } from '@ngrx/store';
// import { Subject } from 'rxjs';
// import { takeUntil } from 'rxjs/operators';

// import {
//   selectorSettings,
//   ActionSettingsChangeTheme,
//   ActionSettingsChangeAutoNightMode,
//   SettingsState,
//   ActionSettingsPersist
// } from '../settings.reducer';

// @Component({
//   selector: 'anms-settings',
//   templateUrl: './settings.component.html',
//   styleUrls: ['./settings.component.scss']
// })
// export class SettingsComponent implements OnInit, OnDestroy {
//   private unsubscribe$: Subject<void> = new Subject<void>();
//   settings: SettingsState;

//   themes = [
//     { value: 'LIGHT-THEME', label: 'Claro' },
//     { value: 'BLACK-THEME', label: 'Oscuro' }
//   ];

//   constructor(private store: Store<any>) {
//     store
//       .select(selectorSettings)
//       .pipe(takeUntil(this.unsubscribe$))
//       .subscribe(settings => (this.settings = settings));
//   }

//   ngOnInit() {}

//   ngOnDestroy(): void {
//     this.unsubscribe$.next();
//     this.unsubscribe$.complete();
//   }

//   onThemeSelect({ value: theme }) {
//     this.store.dispatch(new ActionSettingsChangeTheme({ theme }));
//     this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
//   }

//   onAutoNightModeSelect({ value: autoNightMode }) {
//     this.store.dispatch(
//       new ActionSettingsChangeAutoNightMode({
//         autoNightMode: autoNightMode === 'true'
//       })
//     );
//     this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
//   }
// }

import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
	selectorSettings,
	ActionSettingsChangeTheme,
	ActionSettingsChangeAutoNightMode,
	ActionSettingsChangeAnimationsPage,
	ActionSettingsChangeAnimationsElements,
	SettingsState,
	ActionSettingsPersist
} from '../settings.reducer';

@Component({
	selector: 'anms-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
	private unsubscribe$: Subject<void> = new Subject<void>();
	settings: SettingsState;
	// checked: boolean;

	themes = [
		{ value: 'LIGHT-THEME', label: 'Claro' },
		{ value: 'BLACK-THEME', label: 'Oscuro' }
	];
	constructor(private store: Store<any>, private _location: Location) {
		store
			.select(selectorSettings)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(settings => (this.settings = settings));
	}

	ngOnInit() {
		// switch (this.settings.theme) {
		// 	case 'LIGHT-THEME':
		// 		this.checked = false
		// 		break;
		// 	case 'BLACK-THEME':
		// 		this.checked = true
		// 		break;
		// }
	}

	backBtn() {
		this._location.back();
	}
	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	onThemeSelect({value: theme }) {
		this.store.dispatch(new ActionSettingsChangeTheme({ theme }));
		this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
	}

	// selectTheme({ checked: checked }) {
	// 	this.checked = !this.checked;
	// 	if (this.checked === false) {
	// 		this.onThemeSelect({ theme: 'LIGHT-THEME' })
	// 	} else {
	// 		this.onThemeSelect({ theme: 'BLACK-THEME' })
	// 	}

	// }

	onAutoNightModeToggle({ checked: autoNightMode }) {
		this.store.dispatch(
			new ActionSettingsChangeAutoNightMode({ autoNightMode })
		);
		this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
	}

	onPageAnimationsToggle({ checked: pageAnimations }) {
		this.store.dispatch(
			new ActionSettingsChangeAnimationsPage({ pageAnimations })
		);
		this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
	}

	onElementsAnimationsToggle({ checked: elementsAnimations }) {
		this.store.dispatch(
			new ActionSettingsChangeAnimationsElements({ elementsAnimations })
		);
		this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
	}
}
